import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import $links from './variable';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        Telexa Technology
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
})


class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      errors: '',
      open: false,
      token: '',
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    axios.post($links.authuser, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.errors === false) {
          this.props.history.push('/invoice')
        }
        else {
          this.setState({ errors: res.data.message })
        }
      })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleClose(e, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })

  }
  onSubmit = async (e) => {
    e.preventDefault()
    const user = {
      email: this.state.email,
      password: this.state.password,
      cookies: localStorage.getItem('jwt')
    }

    await axios.post($links.login, user)
      .then(res => {
        if (res.data.errors === false) {
          this.setState({ token: res.data.jwt })
          localStorage.setItem('jwt', res.data.jwt);
          this.props.history.push('/invoice')
        } else {
          this.setState({ open: true })
          this.setState({ errors: res.data.message })


        }
      })


  }

  render() {
    const { classes } = this.props


    return (
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              LOGIN
        </Typography>
            <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity="error">
                {this.state.errors}
              </Alert>
            </Snackbar>
            <form className={classes.form} onSubmit={this.onSubmit}>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={this.state.email}
                onChange={this.onChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.onChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
          </Button>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
  }
}
export default withStyles(useStyles)(Login)
