import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, Container, Grid, Paper, TextField, Typography, Select, FormControl,
  FormControlLabel, Switch, InputLabel
} from '@material-ui/core'
import axios from 'axios'
import $links from './variable';
const useStyles = (theme) => ({

  dates: {
    margin: theme.spacing(2, 1, 3),

  },
  addorder: {
    position: 'absolute',
    width: 150
  },
  adduser: {
    margin: theme.spacing(0, 20, 0),
    width: 150
  },
 
});

class AddCustomer extends Component {

  constructor() {
    super()
    this.state = {
      errors: '',
      product_group: 0,
      name_of_good: '',
      model: '',
      unit_price: '',
      hsn_code: '',
      gst: '',
      status: true,
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleStatus() {
    this.setState({ status: !this.state.status })
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const product = {
      product_group: this.state.product_group,
      name_of_good: this.state.name_of_good,
      model: this.state.model,
      unit_price: this.state.unit_price,
      hsn_code: this.state.hsn_code,
      gst: this.state.gst,
      status: this.state.status,
      create_at: new Date().toLocaleDateString(),
      cookies: localStorage.getItem('jwt')
    }

    await axios.post($links.addproduct, product)
      .then(res => {
        if (res.data.errors === false) {
          this.props.history.push('/product')

        } else {
          this.setState({ open: true })
          this.setState({ errors: res.data.message })

        }
      })


  }
  render() {

    const { classes } = this.props
    return (
      <div>
        <Paper>
          <Container style={{ marginTop: 50 }}>
            <Typography variant="h4" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Add Product</Typography>
            <Typography variant="body1" align="left" style={{ paddingTop: 10, paddingBottom: 15 }}>Enter Details</Typography>
            <form className={classes.form} onSubmit={this.onSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple"> Product Group</InputLabel>
                    <Select
                      fullWidth
                      native
                      label="Product Groups"
                      name="product_group"
                      value={this.state.product_group}
                      onChange={this.onChange}
                      autoFocus
                    >
                      <option value={0} disabled>Select Product Group</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Software">Software</option>
                      <option value="Electronic">Electronic</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Name of Good"
                    placeholder="Enter Name of Good"
                    variant="outlined"
                    fullWidth
                    name="name_of_good"
                    value={this.state.name_of_good}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Model"
                    variant="outlined"
                    fullWidth
                    placeholder="Enter Model"
                    name="model"
                    value={this.state.model}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Unit Price"
                    placeholder="Enter Default Unit Price"
                    variant="outlined"
                    fullWidth
                    name="unit_price"
                    value={this.state.unit_price}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="HSN Code"
                    placeholder="Enter HSN Code"
                    variant="outlined"
                    fullWidth
                    name="hsn_code"
                    value={this.state.hsn_code}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="GST"
                    placeholder="Like 18 for 18% GST"
                    variant="outlined"
                    fullWidth
                    name="gst"
                    value={this.state.gst}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    value="start"
                    checked={this.state.status}
                    onChange={this.handleStatus}
                    control={<Switch color="primary" />}
                    label="Set Status"
                    labelPlacement="start"
                    name="status"
                  />
                </Grid>

              </Grid>
              <Button variant="contained" type="submit" color="primary" style={{ margin: 20 }}>Add Product</Button>
            </form>
          </Container>

        </Paper>

      </div>

    );
  }
}

export default withStyles(useStyles)(AddCustomer)