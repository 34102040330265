import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import $links from './variable';
import Draggable from 'react-draggable';
const useStyles = () => ({
    Typography: {
        marginTop: 10,
        fontWeight: 400,
        lineHeight: 1.4
    }

});
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
class AddressInvoice extends Component {

    constructor() {
        super()
        this.state = {
            entity_name: '',
            person_name: '',
            address: '',
            city: '',
            district: '',
            mobile1: '',
            mobile2: '',
            state: '',
            postal_code: '',
            fontsize0: 50,
            fontsize1: 34,
            fontsize2: 46,
            fontsize3: 34,
            fontsize4: 34,
            fontsize5: 66,
            open: false,
            box: 1
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClosePrint = this.handleClosePrint.bind(this)
        this.handlefont = this.handlefont.bind(this)
        const invoice = {
            invoice_number: localStorage.getItem('generateinvoiceid'),
            cookies: localStorage.getItem('jwt')
        }
        document.title = 'Address Invoice | ' + invoice.invoice_number
        axios.post($links.AddressInvoice, invoice)
            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    const details = res.data.message
                    this.setState({ entity_name: details.entity_name, person_name: details.person_name, postal_code: details.postal_code, city: details.city, address: details.address + ', ', district: ', ' + details.district, mobile2: details.mobile2, mobile1: details.mobile1, state: details.state })
                }
                else {
                    alert(res.data.message)
                }
            })
    }
    handleClose() {
        this.setState({ open: false });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    handleClosePrint = () => {
        this.setState({ open: false })
        setTimeout(() => {
            window.print();
        }, 1000);
    }
    handlefont = (e) => {
        this.setState({ [e.target.name]: parseInt(e.target.value) });
    };

    /*componentDidMount() {
        setTimeout(() => {
            window.print();
        }, 4000);
    }*/

    render() {
        const { classes } = this.props

        return (
            <div id="landscape">
                <style type="text/css">
                    {"@media print{@page {size: landscape}}"}
                </style>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="draggable-dialog-title" PaperComponent={PaperComponent}>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Confirm Cancel</DialogTitle>
                    <DialogContent style={{ minHeight: 200 }}>
                        <DialogContentText>
                            Change Any Details</DialogContentText>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Entity Name"
                                    placeholder="Entity Name"
                                    variant="outlined"
                                    fullWidth
                                    autoFocus
                                    name="entity_name"
                                    value={this.state.entity_name}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Person Name"
                                    placeholder="Person Name"
                                    variant="outlined"
                                    fullWidth
                                    name="person_name"
                                    value={this.state.person_name}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="FontSize"
                                    placeholder="FontSize"
                                    variant="outlined"
                                    fullWidth
                                    name="fontsize0"
                                    InputProps={{
                                        type: 'Number',
                                    }}
                                    value={this.state.fontsize0}
                                    onChange={this.handlefont}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Address"
                                    placeholder="Address"
                                    variant="outlined"
                                    fullWidth
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="District"
                                    placeholder="District"
                                    variant="outlined"
                                    fullWidth
                                    name="district"
                                    value={this.state.district}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="FontSize"
                                    placeholder="FontSize"
                                    variant="outlined"
                                    fullWidth
                                    name="fontsize1"
                                    InputProps={{
                                        type: 'Number',
                                    }}
                                    value={this.state.fontsize1}
                                    onChange={this.handlefont}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="City"
                                    placeholder="City"
                                    variant="outlined"
                                    fullWidth
                                    name="city"
                                    value={this.state.city}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="FontSize"
                                    placeholder="FontSize"
                                    variant="outlined"
                                    fullWidth
                                    name="fontsize2"
                                    InputProps={{
                                        type: 'Number',
                                    }}
                                    value={this.state.fontsize2}
                                    onChange={this.handlefont}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Mobile 1"
                                    placeholder="Mobile 1"
                                    variant="outlined"
                                    fullWidth
                                    name="mobile1"
                                    value={this.state.mobile1}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Mobile 2"
                                    placeholder="Mobile 2"
                                    variant="outlined"
                                    fullWidth
                                    name="mobile2"
                                    value={this.state.mobile2}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Number Of Boxes"
                                    placeholder="Enter Number of Boxes"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        type: 'Number'
                                    }}
                                    name="box"
                                    value={this.state.box}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePrint} color="primary">Print</Button>
                        <Button onClick={this.handleClose} color="primary">Close</Button>
                    </DialogActions>
                </Dialog>
                <Container>
                    <Grid container spacing={2} >
                        <Grid item xs={12} style={{ height: 450 }}>
                            <img src="full_big_tag_line_black.svg" style={{ display: "flex" }} alt="logo" width="400"></img>
                            <Typography variant="h5" style={{
                                fontSize: this.state.fontsize5, position: 'absolute',
                                right: 20,
                                top: 0
                            }}><b>{this.state.box} BOX{this.state.box > 1 ? 'ES' : ''}</b></Typography>
                            <Typography variant="h5" style={{ marginTop: 30, fontSize: this.state.fontsize0 }} align="left"><b>{this.state.entity_name} {this.state.entity_name ? ' - ' : ''} {this.state.person_name}</b></Typography>
                            <Typography variant="body2" align="left" className={classes.Typography} style={{ fontSize: this.state.fontsize1 }}>Address: {this.state.address} <span style={{ fontWeight: 'bold', fontSize: this.state.fontsize2 }}>{this.state.city}</span>  {this.state.district}</Typography>
                            <Typography variant="body2" align="left" className={classes.Typography} style={{ fontSize: this.state.fontsize3 }}>State: {this.state.state + ', ' + this.state.postal_code}</Typography>
                            <Typography variant="body2" align="left" className={classes.Typography} style={{ fontSize: this.state.fontsize4 }}>Phone: {this.state.mobile1}{this.state.mobile1 && this.state.mobile2 ? ', ' : ''} {this.state.mobile2}</Typography>
                        </Grid>
                        <Grid item xs={6} style={{
                            position: 'absolute',
                            bottom: '10%',
                        }}>
                            <hr style={{ borderWidth: 3, border: 'solid #a2a3a2', marginBottom: 10 }} />
                            <Typography align="left">From,</Typography>
                            <Typography align="left">Tech Achiever Engineering Pvt. Ltd.</Typography>
                            <Typography align="left">Address: 34/II-C New Palasia, Indore, Madhya Pradesh, India 452014</Typography>
                            <Typography align="left" style={{ overflow: 'hidden' }}>Mob: 8349913300, 0731-2461800</Typography>
                            <hr style={{ borderWidth: 3, border: 'solid #a2a3a2', marginTop: 10 }} />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => window.top.close()}>Close</Button>
                            <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => this.setState({ open: true })}>Edit</Button>
                            <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => window.print()}>Print</Button>
                            <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => this.props.history.push('/addressinvoice')}>Print Portrait</Button>
                        </Grid>
                    </Grid>
                    <img src="fragile.png" alt="Fragile" style={{
                        position: 'absolute',
                        right: 20,
                        bottom: '10%',
                        width: 200,
                        height: 250
                    }}
                    ></img>
                </Container>
            </div>

        );
    }
}

export default withStyles(useStyles)(AddressInvoice)