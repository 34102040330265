import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios'
import {
  Breadcrumbs, Button, Dialog, DialogActions, FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton,
  DialogContent, DialogTitle, DialogContentText, TextField, Grid, Select, Tooltip, Typography
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Link } from 'react-router-dom';
import $links from './variable';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
const useStyles = (theme) => ({

  button: {
    margin: theme.spacing(5, 0, 0),
  },
  link: {
    textDecoration: 'none',
    color: 'grey'
  },
  Invoice: {

    [theme.breakpoints.up('sm')]: {
      marginLeft: 60, marginRight: 5
    },
  },
  FormControl: {
    paddingTop: 40,
    paddingBottom: 10,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 50,
      paddingRight: 10,
      textAlign: 'right'
    }
  }

});
class Invoice extends Component {

  constructor() {
    super()
    this.state = {
      invoices: [],
      invoice_id: '',
      open: false,
      open1: false,
      errors: '',
      grand_total: '',
      password: '',
      showPassword: false,
      remark: '',
      propss: '',
      customer_id: 1,
      invoice_type: 0,
      Header: "All Invoices",
      changeinvoice: 0
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    axios.post($links.invoicedisplay, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
        }
        else if (res.data.errors === false) {
          this.setState({ invoices: res.data.message })
        }
        else {
          alert(res.data.message)
        }
      })
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChange = (e) => {
    const cookie = { cookies: localStorage.getItem('jwt'), invoice_type: parseInt(e.target.value) }
    switch (cookie.invoice_type) {
      case 0:
        this.setState({ Header: "All Invoices" })
        break;
      case 1:
        this.setState({ Header: "Performa Invoices" })
        break;
      case 2:
        this.setState({ Header: "Quotation Invoices" })
        break;
      default:
        alert('hello')
    }
    axios.post($links.invoicedisplay, cookie)
      .then(res => {
        if (res.data.errors === true) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({ invoices: res.data.message })
        } else {
          this.setState({ errors: res.data.message })
        }
      })
    this.setState({ invoice_type: parseInt(e.target.value) });
  };
  onSubmit = () => {
    const order = {
      invoice_id: this.state.invoice_id,
      grand_total: this.state.grand_total,
      remark: this.state.remark,
      password: this.state.password,
      cookies: localStorage.getItem('jwt')
    }
    axios.post($links.cancelinvoice, order)
      .then(res => {
        if (res.data.error === false) {
          alert(res.data.message)
          window.location.reload(false)
        } else {
          this.setState({ errors: res.data.message })
          alert(this.state.errors)
        }
      })
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleClose() {
    this.setState({ open: false, open1: false });
  };
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    const { classes } = this.props
    var { invoices = [] } = this.state
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Cancel</DialogTitle>
          <DialogContent style={{ minHeight: 200 }}>
            <DialogContentText>
              To Cancel the invoice Enter Password.
          </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Any Remark"
                  placeholder="Any Remark with this Invoice (Optional)"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  name="remark"
                  value={this.state.remark}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChange}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={this.onSubmit} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.open1} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Invoice Type</DialogTitle>
          <DialogContent style={{ minHeight: 200 }}>
            <DialogContentText>
              Select Invoice Type in which you want to change.
          </DialogContentText>
            <Typography style={{ margin: 10 }} align="center">From</Typography>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-age-native-simple">Current Invoice Type</InputLabel>
              <Select
                fullWidth
                native
                disabled
                value={this.state.invoice_type}
                onChange={this.onChange}
                label="Current Invoice Type"
                name="type"
              >
                <option value={0}>Invoices</option>
                <option value={1}>Performa Invoices</option>
                <option value={2}>Quotation Invoices</option>
              </Select>
            </FormControl>
            <Typography style={{ margin: 10 }} align="center">To</Typography>
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 30 }}>
              <InputLabel htmlFor="outlined-age-native-simple">New Invoice Type</InputLabel>
              <Select
                fullWidth
                native
                autoFocus
                value={this.state.changeinvoice}
                onChange={(e) => this.setState({ changeinvoice: e.target.value })}
                label="New Invoice Type"
                name="type"
              >
                <option value={0}>Invoices</option>
                <option value={1}>Performa Invoices</option>
                <option value={2}>Quotation Invoices</option>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={() => {
              const order = {
                invoice_id: this.state.invoice_id,
                changeinvoice: parseInt(this.state.changeinvoice),
                cookies: localStorage.getItem('jwt'),
                grand_total: this.state.grand_total,
                customer_id: this.state.customer_id
              }
              axios.post($links.changeinvoice, order)
                .then(res => {
                  if (res.data.error === false) {
                    alert(res.data.message)
                    window.location.reload(false)
                  } else {
                    this.setState({ errors: res.data.message })
                    alert(this.state.errors)
                  }
                })
            }} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.FormControl}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Invoice Type</InputLabel>
            <Select
              fullWidth
              native
              value={this.state.invoice_type}
              onChange={this.onChange}
              label="Invoice Type"
              name="type"
            >
              <option value={0}>Invoices</option>
              <option value={1}>Performa Invoices</option>
              <option value={2}>Quotation Invoices</option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.Invoice}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
            <Link color="inherit" className={classes.link} to="/customer">
              View Customers</Link>
            <Link color="inherit" className={classes.link} to="/addinvoice">
              Add Invoice</Link>
            <Link color="inherit" className={classes.link} to="/addcustomer">
              Add Customer</Link>
          </Breadcrumbs>
          <MaterialTable
            title={this.state.Header}
            columns={[
              { title: 'Invoice ID', field: 'invoice_id', headerStyle: nowrap, hidden: true },
              { title: 'Invoice Number', field: 'invoice_number', editable: "never", cellStyle: nowrap, headerStyle: nowrap },
              {
                title: 'Customer Name', field: 'customer_name', cellStyle: { whiteSpace: 'nowrap', maxWidth: 140, overflow: 'hidden' }, headerStyle: nowrap, render: (rowData) => (
                  <div>
                    <span key={rowData.customer_name}>{rowData.customer_name[7] === undefined ? '' : rowData.customer_name[7]}{rowData.customer_name[7] && rowData.customer_name[0] ? ', ' : ''}{rowData.customer_name[0]}</span>
                  </div>
                )
              },
              { title: 'Address', field: 'address', cellStyle: { whiteSpace: 'nowrap', maxWidth: 190, overflow: 'hidden' } },
              { title: 'Phone', field: 'phone' },
              { title: 'State', field: 'state', headerStyle: nowrap, },
              { title: 'customer_id', field: 'customer_id', headerStyle: nowrap, hidden: true },
              { title: 'Created', field: 'created', cellStyle: { minWidth: 200 } },
              { title: 'Grand Total', field: 'grand_total', headerStyle: nowrap },
              {
                title: 'Convert', field: 'change_invoice', hidden: this.state.invoice_type === 0 ? true : false, render: rowData => (
                  <div>
                    <Tooltip title="Change Invoice Type">
                      <IconButton color="primary"
                        onClick={() => {
                          this.setState({ open1: true, invoice_id: rowData.invoice_id, customer_id: rowData.customer_id, grand_total: rowData.grand_total })
                        }}
                      ><SwapHorizIcon /></IconButton>
                    </Tooltip>
                  </div>
                )
              }
              , {
                title: 'Cancel Invoice', field: 'cancelled', headerStyle: nowrap, render: rowData => (
                  <div>

                    <Button variant="contained" color="secondary" size="small"
                      onClick={() => {
                        this.setState({ open: true, invoice_id: rowData.invoice_id, grand_total: rowData.grand_total })
                      }}
                      disabled={rowData.cancelled === 1 ? true : false}
                    >Cancel</Button>
                  </div>
                )
              },

            ]}
            data={invoices.map(invoices => (
              {
                invoice_id: invoices.invoice_id, invoice_number: invoices.invoice_number, invoice_date: invoices.invoice_date,
                state: invoices.billto[2], grand_total: Math.round(invoices.grand_total), customer_name: invoices.billto, address: invoices.billto[1],
                phone: invoices.billto[4], gstno: invoices.billto[6], created: invoices.created, cancelled: invoices.cancelled,
                customer_id: invoices.customer_id
              }))
            }
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View Invoice',
                onClick: (event, rowData) => {
                  localStorage.setItem('generateinvoiceid', rowData.invoice_number);
                  const win = window.open("/generateinvoice", "_blank");
                  win.focus();
                }
              }
            ]}
            options={{
              actionsColumnIndex: 7,
              sorting: true,
              padding: "dense",
              exportButton: true,
              pageSize: 10,
              rowStyle: rowData => {
                if (rowData.cancelled === 1) {
                  return { backgroundColor: '#ff816e' };
                }
              }
            }}
          />
        </div>
      </div>

    );
  }
}

export default withStyles(useStyles)(Invoice)