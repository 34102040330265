import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios'
import { Avatar, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import $links from './variable';
import { Alert, Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { ArrowDownward, ArrowUpward, MonetizationOn } from '@material-ui/icons';
const useStyles = (theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 60, marginRight: 5,
      marginTop: 50
    },
  },
  button: {
    margin: theme.spacing(3, 1, 2, 0),
    width: 150
  },
  link: {
    textDecoration: 'none',
    color: 'grey'
  },
  FormControl: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  large1: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#4cc42d'

  },
  large2: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#f50c23'
  },
  large3: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#f0bc11'
  },
  Card: {
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.4)',
    borderRadius: 5
  },
});

class Invoice extends Component {

  constructor() {
    super()
    this.state = {
      accounts: [],
      opendialog: false,
      openerror: false,
      opencancel: false,
      errors: '',
      type: '',
      reason: '',
      date: new Date(),
      transactiondate: new Date(),
      place: '',
      amount: '',
      suggestion: [],
      totalcashin: '',
      totalcashout: 0,
      availablecash: 0,
      id: '',
    }
    this.handleClose = this.handleClose.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleError = this.handleError.bind(this)
    axios.post($links.accountsdisplay, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({ accounts: res.data.message, suggestion: res.data.message, totalcashin: res.data.totalcashin, totalcashout: res.data.totalcashout, availablecash: res.data.availablecash })
        } else {
          this.setState({ errors: res.data.message, openerror: true })
        }
      })
  }
  handleClose = () => {
    this.setState({ opendialog: false, opencancel: false })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleError = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ openerror: false })
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const newaccounts = {
      reason: this.state.reason,
      amount: parseInt(this.state.amount),
      place: this.state.place,
      date: new Date().toLocaleString('en-AU'),
      type: this.state.type,
      transactiondate: this.state.date.toLocaleString('en-AU'),
      cookies: localStorage.getItem('jwt')
    }
    await axios.post($links.addaccounts, newaccounts)
      .then(res => {
        if (res.data.errors === false) {
          alert(res.data.message)
          window.location.reload()
        } else {
          this.setState({ errors: res.data.message, openerror: true })
        }
      })
  }
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    var { accounts = [] } = this.state
    var { suggestion = [] } = this.state
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Snackbar open={this.state.openerror} autoHideDuration={6000} onClose={this.handleError}>
          <Alert onClose={this.handleError} severity="error">
            {this.state.errors}
          </Alert>
        </Snackbar>
        <Dialog
          open={this.state.opencancel}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancel Transaction</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to cancel Transaction
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              const canceldetails = {
                id: this.state.id,
                cookies: localStorage.getItem('jwt')
              }
              axios.post($links.cancelaccounts, canceldetails)
                .then(res => {
                  if (res.data.errors === false) {
                    alert(res.data.message)
                    window.location.reload()
                  } else {
                    this.setState({ errors: res.data.message, openerror: true })
                  }
                })
            }} color="primary">
              Yes
          </Button>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Cancel
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.opendialog} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Add Transaction</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="combo-box-demo"
                  options={suggestion.map((option) => option.reason)}
                  freeSolo
                  onInputChange={(e, value) => {
                    this.setState({ reason: value });                    
                  }}
                  name="autoreason"
                  renderInput={(params) => <TextField {...params} label="Reason of Transaction" autoFocus variant="outlined" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={this.state.type === 'in' ? 'Cash In' : 'Cash Out'}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Amount"
                  name="amount"
                  InputProps={{
                    type: "Number",
                    inputProps: { min: 0 }
                  }}
                  value={this.state.amount}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
              <Autocomplete
                  id="combo-box-demo1"
                  options={suggestion.map((option) => option.place)}
                  freeSolo
                  onInputChange={(e, value) => {
                    this.setState({ place: value });                    
                  }}
                  name="autoplace"
                  renderInput={(params) => <TextField {...params} label="Place"  variant="outlined" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.datepicker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    disableFuture
                    fullWidth
                    label="Date and Time of Transaction"
                    value={this.state.date}
                    onChange={(date) => {
                      this.setState({ date: date })
                    }}
                    format="dd/MM/yyyy hh:mm aa"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <br></br>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSubmit} color="primary">
              Add Transaction
          </Button>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Card className={classes.Card}>
                  <CardContent>
                    <Grid container >
                      <Grid item xs={9} sm={9}>
                        <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL CASH IN</b></Typography>
                        <Typography align="left" variant="h6">₹ {this.state.totalcashin}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Avatar className={classes.large1}><ArrowDownward fontSize="large" /></Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card className={classes.Card}>
                  <CardContent>
                    <Grid container >
                      <Grid item xs={9} sm={9}>
                        <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL CASH OUT</b></Typography>
                        <Typography align="left" variant="h6">₹ {this.state.totalcashout}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Avatar className={classes.large2}><ArrowUpward fontSize="large" /></Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card className={classes.Card}>
                  <CardContent>
                    <Grid container >
                      <Grid item xs={9} sm={9}>
                        <Typography align="left" variant='subtitle1' color="textSecondary" ><b>AVAILABLE CASH</b></Typography>
                        <Typography align="left" variant="h6">₹ {this.state.availablecash}</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3}>
                        <Avatar className={classes.large3}><MonetizationOn fontSize="large" /></Avatar>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.FormControl}>
              <Button variant="contained" color="primary" style={{ backgroundColor: '#d90707' }} onClick={() => this.setState({ type: 'out', opendialog: true, transactiondate: new Date() })} className={classes.button} >Cash out ₹</Button>
              <Button variant="contained" color="primary" style={{ backgroundColor: '#2bad03' }} onClick={() => this.setState({ type: 'in', opendialog: true, transactiondate: new Date() })} className={classes.button} >Cash in ₹</Button>
            </div>
          </Grid>
        </Grid>
        <br />
        <MaterialTable
          title="Accounts"
          columns={[
            { title: 'ID', field: 'id', headerStyle: nowrap, cellStyle: { width: '1%' } },
            { title: 'Reason', field: 'reason', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 350, overflow: 'hidden' } },
            { title: 'Date', field: 'date', headerStyle: nowrap },
            { title: 'Date of Transaction', field: 'transactiondate', headerStyle: nowrap },
            { title: 'Place', field: 'place', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 100, overflow: 'hidden' } },
            { title: 'Cash-In', field: 'in', headerStyle: nowrap, cellStyle: { width: '3%', textAlignLast: 'right', whiteSpace: 'nowrap', } },
            { title: 'Cash-Out', field: 'out', headerStyle: nowrap, cellStyle: { width: '5%', textAlignLast: 'right', whiteSpace: 'nowrap', } },
            { title: 'Balance', field: 'balance', headerStyle: nowrap, cellStyle: { width: '5%', textAlignLast: 'right', whiteSpace: 'nowrap', } },
            {
              title: 'Cancel', field: 'cancel', align: 'center', headerStyle: nowrap, render: rowData => (
                <div style={{ textAlign: 'center' }}>

                  <Button variant="contained" color="secondary" size="small"
                    onClick={() => {
                      this.setState({ opencancel: true, id: rowData.id })
                    }}
                    disabled={rowData.cancel === 1 ? true : false}
                  >{rowData.cancel === 1 ? 'Cancelled' : 'Cancel'}</Button>
                </div>
              )
            },
            { title: 'Added / Cancelled By', field: 'addedby', headerStyle: nowrap },
          ]}
          data={accounts.map(accounts => (
            {
              id: accounts.id, reason: accounts.reason, date: accounts.date,
              transactiondate: accounts.transactiondate, place: accounts.place, in: accounts.in ? '+ ₹' + accounts.in : '',
              out: accounts.out ? '- ₹' + accounts.out : '', balance: '₹' + accounts.balance, cancel: accounts.cancelled,
              addedby: accounts.addedby
            }))
          }
          options={{
            sorting: false,
            draggable: false,
            pageSize: 20,
            exportButton: true,
            padding: 'dense',
            rowStyle: rowData => {

              if (rowData.cancel === 1) {
                return { backgroundColor: '#ff7a75' };
              }
              else if (rowData.in) {
                return { backgroundColor: '#ebebeb' };
              }


            },
          }}
        />
      </div >

    );
  }
}

export default withStyles(useStyles)(Invoice)