import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import {
  Button, CardActions, Card, Typography, CardContent, Grid, Container, Dialog, DialogActions, DialogContentText
  , DialogContent, DialogTitle, TextField, FormControlLabel, Switch, Breadcrumbs
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { Link, NavLink } from 'react-router-dom'
import $links from './variable';
const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(4, 0, 2),
  },
  Typography: {
    fontSize: 18,
    lineHeight: 1.5
  },
  link: {
    textDecoration: 'none',
    color: 'grey'
  }
});

class Products extends Component {

  constructor() {
    super()
    this.state = {
      products: [],
      errors: '',
      product_group: '',
      notfound: '',
      product_id: '',
      name_of_good: '',
      model: '',
      unit_price: '',
      hsn_code: '',
      gst: '',
      status: '',
      open: false,
      open1: false,
      cookies: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClose1 = this.handleClose1.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.onChange = this.onChange.bind(this)
    var displaytype = {
      product_group: localStorage.getItem('product'),
      cookies: localStorage.getItem('jwt')
    }
    axios.post($links.displayproduct, displaytype)
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          if (displaytype.product_group === undefined) {
            this.setState({ product_group: 'Agriculture' })
          } else {
            this.setState({ products: res.data.message, product_group: res.data.product_group, cookies: displaytype.product_group })
          }
          if (res.data.message[0] === undefined) {
            this.setState({ notfound: "No Products Found" })
          }
        }
        else {
          alert(res.data.message)
        }
      })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  handleClose1(e, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open1: false })

  }
  handleStatus = () => {
    this.setState({ status: !this.state.status })
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      var displaytype = {
        product_group: localStorage.getItem('product'),
        cookies: localStorage.getItem('jwt'),
      }
      const cookie = this.state.cookies
      if (displaytype.product_group !== cookie) {
        this.setState({ cookies: displaytype.product_group })
        axios.post($links.displayproduct, displaytype)
          .then(res => {
            if (res.data.errors === true) {
              alert(res.data.message)
              window.location.replace('/')
            }
            if (res.data.errors === false) {
              if (displaytype.product_group === undefined) {
                this.setState({ product_group: 'Agriculture' })
              } else {
                this.setState({ products: res.data.message, product_group: res.data.product_group, notfound: "" })
              }
              if (res.data.message[0] === undefined) {
                this.setState({ notfound: "No Products Found" })
              }
            } else {
              this.setState({ errors: res.data.message, open1: true })
            }
          })
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const updatedproduct = {
      product_id: this.state.product_id,
      name_of_good: this.state.name_of_good,
      model: this.state.model,
      unit_price: this.state.unit_price,
      hsn_code: this.state.hsn_code,
      gst: this.state.gst,
      status: this.state.status,
    }
    await axios.post($links.editproduct, updatedproduct)
      .then(res => {

        if (res.data.errors === false) {
          this.setState({ open: false })
          alert(res.data.message)
          window.location.reload()
        } else {
          this.setState({ errors: res.data.message, open1: true })
        }
      })
  }
  render() {
    var { products = [] } = this.state
    const { classes } = this.props
    return (
      <div style={{ marginTop: 20 }}>
        <Snackbar open={this.state.open1} autoHideDuration={6000} onClose={this.handleClose1}>
          <Alert onClose={this.handleClose1} severity="error">
            {this.state.errors}
          </Alert>
        </Snackbar>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Edit Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Showing the details of {this.state.product_group} Product with Product ID: {this.state.product_id}
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Name of Good"
                  placeholder="Enter Name of Good"
                  variant="outlined"
                  fullWidth
                  name="name_of_good"
                  value={this.state.name_of_good}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter Model"
                  name="model"
                  value={this.state.model}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Unit Price"
                  placeholder="Enter Default Unit Price"
                  variant="outlined"
                  fullWidth
                  name="unit_price"
                  value={this.state.unit_price}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="HSN Code"
                  placeholder="Enter HSN Code"
                  variant="outlined"
                  fullWidth
                  name="hsn_code"
                  value={this.state.hsn_code}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="GST"
                  placeholder="Like 18 for 18% GST"
                  variant="outlined"
                  fullWidth
                  name="gst"
                  value={this.state.gst}
                  onChange={this.onChange}
                />
              </Grid>
              <FormControlLabel style={{ marginLeft: 70 }}
                value="start"
                checked={this.state.status}
                onChange={this.handleStatus}
                control={<Switch color="primary" />}
                label="Change Status"
                labelPlacement="start"
                name="status"
              />
            </Grid>
            <br></br>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSubmit} color="primary">
              Confirm Change
          </Button>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>

          </DialogActions>
        </Dialog>
        <Button variant="contained" color="primary" component={NavLink} to='/addproduct' className={classes.button} >Add Product</Button>
        <Container>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 15, marginBottom: 10 }}>
            <Link color="inherit" className={classes.link} to="/addinvoice">
              Add Invoice</Link>
            <Link color="inherit" className={classes.link} to="/invoice">
              View Invoices</Link>
            <Link color="inherit" className={classes.link} to="/addcustomer">
              Add Customer</Link>
            <Link color="inherit" className={classes.link} to="/customer">
              View Customers</Link>
          </Breadcrumbs>
          <Typography align="left" style={{ margin: 15 }} variant="h4">
            {this.state.product_group.toUpperCase()} PRODUCTS
        </Typography>
          <Grid container spacing={3}>
            {products.map(products => (
              <Grid item xs={12} sm={4} key={products.product_id}>
                <Card className={classes.root} >
                  <CardContent>
                    <Typography align="left" className={classes.Typography}>
                      <b>Name of Good:</b> {products.name_of_good}</Typography>
                    <Typography className={classes.Typography} align="left">
                      <b>Model: </b> {products.model}</Typography>
                    <Typography className={classes.Typography} align="left">
                      <b>Default Unit Price: </b> ₹ {products.unit_price}
                    </Typography>
                    <Typography className={classes.Typography} align="left">
                      <b>GST: </b> {products.gst}%
                    </Typography>
                    <Typography className={classes.Typography} align="left">
                      <b>HSN Code: </b> {products.hsn_code}
                    </Typography>
                    <Typography className={classes.Typography} align="left">
                      <b>Status: </b>{(() => {
                        if (products.status) {
                          return "Active"
                        } else {
                          return "Not Active"
                        }
                      })()}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button color="primary" onClick={() => {
                      this.setState({
                        product_id: products.product_id,
                        product_group: products.product_group,
                        name_of_good: products.name_of_good,
                        model: products.model,
                        unit_price: products.unit_price,
                        hsn_code: products.hsn_code,
                        gst: products.gst,
                        status: products.status,
                        open: true
                      })
                    }}
                    >Edit Product</Button>
                  </CardActions>
                </Card>
              </Grid>

            ))
            }
          </Grid>
          <Typography variant="h2" style={{ marginTop: 100 }}>{this.state.notfound}</Typography>
        </Container>
      </div>

    );
  }
}

export default withStyles(useStyles)(Products)