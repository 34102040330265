import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, Paper, TextField, Typography, Snackbar } from '@material-ui/core'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import $links from './variable'
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = (theme) => ({
    dates: {
        margin: theme.spacing(2, 1, 3),

    },
    addorder: {
        position: 'absolute',
        width: 150
    },
    adduser: {
        margin: theme.spacing(0, 20, 0),
        width: 150
    }
});

class Shipping extends Component {

    constructor() {
        super()
        this.state = {
            id: '',
            invoice_number: '',
            invoice_numbers: [],
            shipping_mode: '',
            shipping_charge: '',
            shipping_gst: '',
            tracking_id: '',
            dispatch_date: new Date(),
            delivered_date: new Date(),
            remark: '',
            errors: '',
            open: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        axios.post($links.invoicedisplay, { cookies: localStorage.getItem('jwt') })
            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    this.setState({ invoice_numbers: res.data.message })
                }
                else {
                    alert(res.data.message)
                }
            })
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleClose(e, reason) {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ open: false })

    }

    onSubmit = async (e) => {
        e.preventDefault()
        const customer = {
            id: this.state.id,
            invoice_number: this.state.invoice_number,
            shipping_mode: this.state.shipping_mode,
            shipping_charge: this.state.shipping_charge,
            shipping_gst: this.state.shipping_gst,
            tracking_id: this.state.tracking_id,
            dispatch_date: this.state.dispatch_date,
            delivered_date: this.state.delivered_date,
            remark: this.state.remark,
            create_at: new Date().toLocaleDateString(),
            cookies: localStorage.getItem('jwt')
        }
        await axios.post($links.editshipping, customer)
            .then(res => {
                if (res.data.errors === false) {
                    alert(res.data.message)
                    this.props.history.push('/invoice')
                } else {
                    this.setState({ errors: res.data.message, open: true })
                }
            })

    }
    render() {
        const { classes } = this.props
        var { invoice_numbers = [] } = this.state
        return (
            <div>
                <Paper style={{ margin: 5 }}>
                    <Container style={{ marginTop: 50 }}>
                        <Typography variant="h4" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Update Shipping Details</Typography>
                        <Typography variant="body1" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Enter Details</Typography>
                        <Snackbar open={this.state.open} autoHideDuration={4000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity="error">
                                {this.state.errors}
                            </Alert>
                        </Snackbar>
                        <form onSubmit={this.onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={invoice_numbers}
                                        onChange={async (event, newValue) => {
                                            if (newValue) {
                                                const value = { invoice_number: newValue.invoice_number, cookies: localStorage.getItem('jwt') }
                                                await axios.post($links.findshipping, value)
                                                    .then(res => {
                                                        if (res.data.errors === false) {
                                                            if (res.data.message) {
                                                                this.setState({
                                                                    id: res.data.message.id,
                                                                    invoice_number: newValue.invoice_number,
                                                                    shipping_mode: res.data.message.shipping_mode,
                                                                    shipping_charge: res.data.message.shipping_charge,
                                                                    shipping_gst: res.data.message.shipping_gst,
                                                                    tracking_id: res.data.message.tracking_id,
                                                                    remark: res.data.message.remark,
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    id: '',
                                                                    invoice_number: '',
                                                                    shipping_mode: '',
                                                                    shipping_charge: '',
                                                                    shipping_gst: '',
                                                                    tracking_id: '',
                                                                    remark: '',
                                                                })
                                                            }
                                                        } else {
                                                            this.setState({ errors: res.data.message, open: true })
                                                        }
                                                    })
                                            } else {
                                                this.setState({
                                                    id: '',
                                                    invoice_number: '',
                                                    shipping_mode: '',
                                                    shipping_charge: '',
                                                    shipping_gst: '',
                                                    tracking_id: '',
                                                    remark: '',
                                                })
                                            }
                                        }}
                                        autoFocus
                                        getOptionLabel={(option) => option.invoice_number + " || " + option.billto[0]}
                                        name="invoice_number"
                                        renderInput={(params) => <TextField {...params} label="Search Invoice Number" autoFocus variant="outlined" fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="outline-flexible"
                                        label="Entity Shipping Mode"
                                        placeholder="Enter Shipping Mode"
                                        variant="outlined"
                                        fullWidth
                                        name="shipping_mode"
                                        value={this.state.shipping_mode}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Shipping Charge"
                                        placeholder="Enter Shipping Charge"
                                        variant="outlined"
                                        fullWidth
                                        name="shipping_charge"
                                        value={this.state.shipping_charge}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Shipping GST"
                                        placeholder="Enter Shipping GST"
                                        variant="outlined"
                                        fullWidth
                                        name="shipping_gst"
                                        value={this.state.shipping_gst}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Tracking ID"
                                        placeholder="Enter Shipping GST"
                                        variant="outlined"
                                        fullWidth
                                        name="tracking_id"
                                        value={this.state.tracking_id}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField className={classes.textss}
                                        label="Any Remark"
                                        placeholder="(Optional)"
                                        variant="outlined"
                                        fullWidth
                                        name="remark"
                                        value={this.state.remark}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={12} sm={3}>
                                        <KeyboardDatePicker
                                            style={{ margin: 0 }}
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline1"
                                            name="invoice_date"
                                            label="Select Dispatch Date"
                                            value={this.state.dispatch_date}
                                            onChange={(date) => {
                                                this.setState({ dispatch_date: date });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <KeyboardDatePicker
                                            style={{ margin: 0 }}
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline1"
                                            name="invoice_date"
                                            label="Select Delivered Date"
                                            value={this.state.delivered_date}
                                            onChange={(date) => {
                                                this.setState({ delivered_date: date });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>


                            </Grid>
                            <Button variant="contained" type="submit" color="primary" style={{ margin: 20 }}>Update Shipping Details</Button>
                        </form>
                    </Container>

                </Paper>

            </div>

        );
    }
}

export default withStyles(useStyles)(Shipping)