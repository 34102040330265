import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import {
    Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select,
    Dialog, DialogActions, DialogTitle, DialogContent, Hidden, Divider, Breadcrumbs, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core'
import axios from 'axios';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import $links from './variable';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = (theme) => ({

    dates: {
        margin: theme.spacing(2, 1, 3),

    },
    addorder: {
        position: 'absolute',
        width: 150
    },
    Typography: {
        margin: theme.spacing(1),
        fontSize: 16
    },
    link: {
        textDecoration: 'none',
        color: 'grey'
    },
    Paper: {
        marginTop: 60,
        [theme.breakpoints.up('sm')]: {
            marginTop: 60, marginLeft: 60, marginRight: 5
        }
    }
});

class AddInvoice extends Component {

    constructor() {
        super()
        this.state = {
            customer: [],
            products: [],
            allproducts: [],
            shipping: [{ shipping_charge: 0, shipping_gst: 18, dispatch_date: new Date(), shipping_mode: 'Courier' }],
            shipping_total: 0,
            errors: '',
            hidden: ['sm', 'lg', 'xs', 'md', 'xl'],
            open: false,
            name: '',
            newproduct_model: 0,
            invoice_number: "",
            invoice_date: new Date(),
            po_number: "-", po_date: new Date(),
            dc_number: '-', dc_date: new Date(),
            grand_total: 0,
            invoice_type: "0",
            remark: '',
            AutocompleteOpen: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onRadioChange = this.onRadioChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        const type = {
            type: "invoice",
            cookies: localStorage.getItem('jwt')
        }
        axios.post($links.customerdisplay, type)
            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    if (res.data.message[0] === undefined) {
                        alert("Please add customer first")
                        this.props.history.push('/addcustomer')
                    } else {
                        this.setState({ customer: res.data.message })
                        const date = new Date()
                        var mm = date.getMonth() + 1
                        var yy = date.getFullYear().toString().substr(-2)
                        if (mm < 10) {
                            this.setState({ invoice_number: "TA" + yy + "0" + mm + '/' + res.data.message2 })
                        } else {
                            this.setState({ invoice_number: "TA" + yy + mm + '/' + res.data.message2 })
                        }
                    }
                }
                else {
                    alert(res.data.message)
                }
            })
        axios.post($links.productdisplay)
            .then(res => {
                if (res.data.errors === true) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                if (res.data.errors === false) {
                    if (res.data.message1[0] === undefined) {
                        alert("Please add product first")
                        this.props.history.push('/addproduct')
                    } else {
                        this.setState({ products: res.data.message, allproducts: res.data.message1 })
                    }
                } else {
                    this.setState({ errors: res.data.message })

                }
            })
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onRadioChange(e) {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.type === "radio") {
            if (e.target.value) {
                axios.post($links.invoice_type, { invoice_type: e.target.value })
                    .then(res => {
                        if (res.data.errors === true) {
                            alert(res.data.message)
                            window.location.replace('/')
                        }
                        if (res.data.errors === false) {
                            this.setState({ invoice_number: res.data.message })
                        }
                    })
            }
        }
    }
    handleClose = () => {
        this.setState({ open: false, texterror: "" })
    };
    handleSubmit = async (e) => {
        e.preventDefault()
        var i = 0
        var grand_total = 0
        var shipping = 0
        if (this.state.products) {
            if (this.state.shipping[0]) {
                shipping = this.state.shipping[0].shipping_charge + ((this.state.shipping[0].shipping_charge * this.state.shipping[0].shipping_gst) / 100)
            }
            while (i < this.state.products.length) {
                grand_total = grand_total + this.state.products[i].qty * (this.state.products[i].unit_price + (this.state.products[i].unit_price * this.state.products[i].gst / 100))
                this.state.products[i].unit_price = Math.round(this.state.products[i].unit_price)
                i++
            }
            if (shipping) {
                grand_total = grand_total + shipping
            }
        }
        const data = {
            invoice_number: this.state.invoice_number,
            customer_id: this.state.name.customer_id,
            product_details: this.state.products,
            invoice_date: this.state.invoice_date.toLocaleDateString('en-GB'),
            po_number: this.state.po_number, po_date: this.state.po_date.toLocaleDateString('en-GB'),
            dc_number: this.state.dc_number, dc_date: this.state.dc_date.toLocaleDateString('en-GB'),
            grand_total: grand_total, created: new Date().toLocaleString(),
            shipping: shipping,
            invoice_type: parseInt(this.state.invoice_type),
            remark: this.state.remark
        }
        const shippings = {
            invoice_number: this.state.invoice_number,
            shipping_mode: this.state.shipping[0].shipping_mode,
            shipping_charge: this.state.shipping[0].shipping_charge,
            shipping_gst: this.state.shipping[0].shipping_gst,
            tracking_id: this.state.shipping[0].tracking_id,
            dispatch_date: this.state.shipping[0].dispatch_date,
            delivered_date: this.state.shipping[0].delivered_date,
            remark: this.state.shipping[0].remark,
        }
        await axios.post($links.addinvoice, data)
            .then(async (res) => {
                if (res.data.errors === false) {
                    localStorage.setItem('generateinvoiceid', data.invoice_number);
                    alert(res.data.message)
                    await axios.post($links.shipping, shippings)
                    window.location.replace('/generateinvoice')
                } else {
                    alert(res.data.message)
                }
            })

    }
    render() {
        const { classes } = this.props
        var { products = [] } = this.state
        var { allproducts = [] } = this.state
        var { customer = [] } = this.state
        var { name = [] } = this.state
        var { shipping = [] } = this.state
        return (
            <div>
                <Dialog open={this.state.open} fullWidth maxWidth="sm" onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Product Details</DialogTitle>
                    <DialogContent>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="newproduct_model">Products</InputLabel>
                            <Select
                                native
                                value={this.state.newproduct_model}
                                onChange={this.onChange}
                                label="Product"
                                autoFocus
                                name="newproduct_model"
                                fullWidth={true}
                            >
                                <option value={0} disabled>Select any Product to add</option>
                                {allproducts.map(allproducts => (
                                    <option key={allproducts.model} value={allproducts.model}>{"Model: " + allproducts.model +
                                        " | Product Name: " + allproducts.name_of_good}</option>

                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            var i = 0
                            var addedprod = []
                            while (i < allproducts.length) {
                                if (allproducts[i].model === this.state.newproduct_model) {
                                    addedprod = allproducts[i]
                                }
                                i++
                            }
                            this.setState({
                                products: this.state.products.concat(addedprod), open: false, newproduct_model: ''
                            })
                        }} color="primary">Submit</Button>
                        <Button onClick={this.handleClose} color="primary">Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Paper className={classes.Paper}>
                    <Container>
                        <Breadcrumbs aria-label="breadcrumb" style={{ paddingTop: 10 }}>
                            <Link color="inherit" className={classes.link} to="/customer">
                                View Customers</Link>
                            <Link color="inherit" className={classes.link} to="/invoice">
                                View Invoices</Link>
                            <Link color="inherit" className={classes.link} to="/addcustomer">
                                Add Customer</Link>
                        </Breadcrumbs>
                        <Typography variant="h4" align="left" style={{ paddingTop: 10 }}>Generate Invoice</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <RadioGroup name="invoice_type" value={this.state.invoice_type} onChange={this.onRadioChange} row>
                                    <Grid item xs={4} sm={2}>
                                        <FormControlLabel value="0" control={<Radio />} label="Invoice" />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControlLabel value="1" control={<Radio />} label="Performa Invoice" />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControlLabel value="2" control={<Radio />} label="Quotation Invoice" />
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                            <Typography variant="body1" align="left" style={{ paddingBottom: 10, paddingLeft: 10 }}>Enter Details</Typography>
                            <Grid item xs={12} sm={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={customer}
                                    open={this.state.AutocompleteOpen}
                                    onChange={(event, newValue) => {
                                        this.setState({ name: newValue, hidden: [], AutocompleteOpen: false });
                                        if (!newValue) {
                                            this.setState({ hidden: ['sm', 'lg', 'xs', 'md', 'xl'], name: undefined })
                                        }
                                    }}
                                    onInputChange={(e, value) => {
                                        if (value.length > 2)
                                            this.setState({ AutocompleteOpen: true })
                                        else
                                            this.setState({ AutocompleteOpen: false })
                                    }}
                                    getOptionLabel={(option) => option.entity_name + ' || ' + option.person_name + ' || ' + option.mobile1 + ' || ' + option.district}
                                    name="name"
                                    renderInput={(params) => <TextField {...params} label="Search Customer" autoFocus variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Hidden only={this.state.hidden}>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="left" variant="h4">Entity Details</Typography>
                                </Grid>
                                <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Entity Name: </b>{name.entity_name}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Person Name: </b>{name.person_name}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography} ><b>Mobile Number 1: </b>{name.mobile1}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Mobile Number 2: </b>{name.mobile2}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>GST No: </b>{name.gstno}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Email: </b>{name.email}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Address: </b>{name.address}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Balance: </b>{Math.round(name.balance)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>State: </b>{name.state}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>District: </b>{name.district}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>City: </b>{name.city}</Typography>
                                        <Typography align="left" variant="body2" className={classes.Typography}><b>Postal Code: </b>{name.postal_code}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography align="left" variant="h5">About Bill</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="outline-flexible1"
                                        label="Invoice Number"
                                        placeholder="Enter Invoice Number"
                                        variant="outlined"
                                        fullWidth
                                        name="invoice_number"
                                        value={this.state.invoice_number}
                                        autoFocus
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="outline-flexible2"
                                        label="PO Number"
                                        placeholder="Enter PO Number"
                                        variant="outlined"
                                        fullWidth
                                        name="po_number"
                                        value={this.state.po_number}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="outline-flexible3"
                                        label="DC Number"
                                        placeholder="Enter DC Number"
                                        variant="outlined"
                                        fullWidth
                                        name="dc_number"
                                        value={this.state.dc_number}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item xs={12} sm={4}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline1"
                                            name="invoice_date"
                                            label="Select Invoice Date"
                                            value={this.state.invoice_date}
                                            onChange={(date) => {
                                                this.setState({ invoice_date: date });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline3"
                                            name="po_date"
                                            label="Select PO Date"
                                            value={this.state.po_date}
                                            onChange={(date) => {
                                                this.setState({ po_date: date });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <KeyboardDatePicker
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline2"
                                            name="dc_date"
                                            label="Select DC Date"
                                            value={this.state.dc_date}
                                            onChange={(date) => {
                                                this.setState({ dc_date: date });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outline-flexible4"
                                        label="Remark"
                                        placeholder="Any Remark with this Invoice (Optional)"
                                        variant="outlined"
                                        fullWidth
                                        name="remark"
                                        value={this.state.remark}
                                        onChange={this.onChange}
                                    /><br /><br />
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <MaterialTable
                                        title="Product Details"
                                        columns={[
                                            { title: 'S.no.', field: 'product_id' },
                                            { title: 'Name of goods', field: 'name_of_good', headerStyle: { whiteSpace: "nowrap" }, cellStyle: { whiteSpace: "nowrap" } },
                                            { title: 'Model', field: 'model' },
                                            { title: "Qty", field: 'qty', emptyValue: 1, type: "numeric", align: "center", headerStyle: { whiteSpace: "nowrap" } },
                                            { title: 'Unit Price', field: 'unit_price', type: "numeric", align: "center", },
                                            { title: 'HSN Code', field: 'hsn_code' },
                                            { title: 'GST', field: 'gst' },
                                            { title: 'Grand Total', field: 'grand_total', editable: "onUpdate", headerStyle: { whiteSpace: "nowrap" }, },
                                        ]}
                                        data={products.map(products => (
                                            {
                                                product_id: products.product_id, name_of_good: products.name_of_good,
                                                model: products.model, qty: products.qty, unit_price: Math.round(products.unit_price),
                                                hsn_code: products.hsn_code, gst: products.gst, grand_total: Math.floor(products.qty * (products.unit_price + (products.unit_price * products.gst / 100)))
                                            }))}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        const dataUpdate = [...products];
                                                        const index = oldData.tableData.id;
                                                        var TP = newData.grand_total
                                                        var r = newData.gst / 100
                                                        if (newData.grand_total !== oldData.grand_total) {
                                                            newData.unit_price = TP - (TP / (1 + r) * r)
                                                            if (newData.qty === oldData.qty) {
                                                                newData.grand_total = newData.grand_total / newData.qty
                                                                newData.unit_price = newData.unit_price / newData.qty
                                                            }
                                                        }

                                                        dataUpdate[index] = newData;
                                                        this.setState({ products: [...dataUpdate] });
                                                        resolve();
                                                    }, 500)
                                                }),
                                            onRowDelete: oldData =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        const dataDelete = [...products];
                                                        const index = oldData.tableData.id;
                                                        dataDelete.splice(index, 1);
                                                        this.setState({ products: [...dataDelete] });
                                                        resolve()
                                                    }, 1000)
                                                }),
                                        }}
                                        options={{
                                            search: false,
                                            paging: false,
                                            actionsColumnIndex: -1,
                                            draggable: false,
                                            sorting: false,
                                        }}
                                        actions={[
                                            {
                                                icon: 'add',
                                                tooltip: 'Add Product',
                                                isFreeAction: true,
                                                onClick: (event) => this.setState({ open: true })
                                            }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <MaterialTable
                                        title="Shipping Details"
                                        columns={[
                                            { title: 'Tracking ID', field: 'tracking_id' },
                                            { title: 'Shipping Mode', field: 'shipping_mode', lookup: { 'Courier': 'Courier', 'COD': 'COD', 'Transport': 'Transport', 'Travels': 'Travels', 'Takeaway': 'Takeaway', 'Other': 'Other' } },
                                            { title: 'Shipping Charge', field: 'shipping_charge', type: "numeric" },
                                            { title: 'Shipping GST', field: 'shipping_gst', type: 'numeric' },
                                            { title: 'Shipping Total', field: 'grand_total', type: 'numeric' },
                                            { title: "Dispatch Date", field: 'dispatch_date', type: "date", headerStyle: { whiteSpace: "nowrap" } },
                                            { title: 'Delivered Date', field: 'delivered_date', type: "date" },
                                            { title: 'Remark', field: 'remark', },
                                        ]}
                                        data={shipping.map(shipping => (
                                            {
                                                tracking_id: shipping.tracking_id, shipping_mode: shipping.shipping_mode,
                                                shipping_charge: shipping.shipping_charge, shipping_gst: shipping.shipping_gst,
                                                dispatch_date: shipping.dispatch_date, delivered_date: shipping.delivered_date,
                                                grand_total: Math.floor(shipping.shipping_charge + (shipping.shipping_charge * shipping.shipping_gst / 100))
                                            }))}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        const dataUpdate = [...shipping];
                                                        const index = oldData.tableData.id;
                                                        var TP = newData.grand_total
                                                        var r = newData.shipping_gst / 100
                                                        if (newData.grand_total !== oldData.grand_total) {
                                                            newData.shipping_charge = Math.round(TP - (TP / (1 + r) * r))
                                                        }
                                                        dataUpdate[index] = newData;
                                                        this.setState({ shipping: [...dataUpdate] });
                                                        resolve();
                                                    }, 500)
                                                }),

                                        }}
                                        options={{
                                            search: false,
                                            paging: false,
                                            actionsColumnIndex: -1,
                                            draggable: false,
                                            sorting: false,
                                        }}

                                    />
                                </Grid>

                            </Hidden>
                        </Grid>
                        <Hidden only={this.state.hidden}>
                            <Button variant="contained" onClick={this.handleSubmit} color="primary" style={{ margin: 20 }}>Save Invoice</Button>
                        </Hidden>
                    </Container>

                </Paper>

            </div>

        );
    }
}

export default withStyles(useStyles)(AddInvoice)