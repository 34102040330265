const $links = {
    postal_code: 'http://51.158.109.251:5001/customer/pincode',
    createcustomer: 'http://51.158.109.251:5001/customer/createcustomer',
    addinvoice: 'http://51.158.109.251:5001/invoice/addinvoice',
    customerdisplay: 'http://51.158.109.251:5001/customer',
    productdisplay: 'http://51.158.109.251:5001/product/display',
    addproduct: 'http://51.158.109.251:5001/product/addproduct',
    EditCustomer: 'http://51.158.109.251:5001/customer/edit',
    findcustomer: 'http://51.158.109.251:5001/customer/find',
    findinvoice: 'http://51.158.109.251:5001/invoice/findinvoice',
    invoicedisplay: 'http://51.158.109.251:5001/invoice',
    cancelinvoice: 'http://51.158.109.251:5001/invoice/cancel',
    shipping: 'http://51.158.109.251:5001/invoice/shipping',
    editshipping: 'http://51.158.109.251:5001/invoice/editshipping',
    findshipping: 'http://51.158.109.251:5001/invoice/findshipping',
    authuser: 'http://51.158.109.251:5001/auth/authuser',
    login: 'http://51.158.109.251:5001/auth/login',
    permissions: 'http://51.158.109.251:5001/auth/permissions',
    editproduct: 'http://51.158.109.251:5001/product/editproduct',
    displayproduct: 'http://51.158.109.251:5001/product/displayproduct',
    addtransaction: 'http://51.158.109.251:5001/transaction/addtransaction',
    updatetransaction: 'http://51.158.109.251:5001/transaction/update',
    customerprofile: 'http://51.158.109.251:5001/customer/profile',
    invoice_type: 'http://51.158.109.251:5001/invoice/invoicetype',
    enquiry: 'http://51.158.109.251:5001/enquiry',
    addenquiry: 'http://51.158.109.251:5001/enquiry/addenquiry',
    enquirydashboard: 'http://51.158.109.251:5001/enquiry/dashboard',
    viewenquiry: 'http://51.158.109.251:5001/enquiry/viewenquiry',
    updateenquiry: 'http://51.158.109.251:5001/enquiry/update',
    changeinvoice: 'http://51.158.109.251:5001/invoice/changeinvoice',
    AddressInvoice: 'http://51.158.109.251:5001/invoice/addressinvoice',
    selectoptions: 'http://51.158.109.251:5001/customer/selectoption',
    changeselectoptions: 'http://51.158.109.251:5001/customer/changeselectoption',
    deletecustomer: 'http://51.158.109.251:5001/customer/delete',
    indiamartsync: 'http://51.158.109.251:5001/enquiry/indiamartsync',
    indiamartenquiry: 'http://51.158.109.251:5001/enquiry/indiamartenquiry',
    accountsdisplay: 'http://51.158.109.251:5001/transaction/accountsdisplay',
    addaccounts: 'http://51.158.109.251:5001/transaction/addaccounts',
    cancelaccounts: 'http://51.158.109.251:5001/transaction/cancelaccounts',
    displayuser: 'http://51.158.109.251:5001/admin/displayuser',
    adduser: 'http://51.158.109.251:5001/admin/adduser',
    updateuser: 'http://51.158.109.251:5001/admin/updateuser',
    deleteuser: 'http://51.158.109.251:5001/admin/deleteuser',
    filterenquiry: 'http://51.158.109.251:5001/enquiry/filter',
    allenquiries: 'http://51.158.109.251:5001/enquiry/all',
    existingmobile: 'http://51.158.109.251:5001/enquiry/existingmobile',
}

export default $links     