import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios'
import { Button, Container, Divider, Grid, Paper, Typography, Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import $links from './variable';
const useStyles = (theme) => ({

  button: {
    margin: theme.spacing(5, 0, 2),
  },
  Typography: {
    fontSize: 17.5
  },
  link: {
    textDecoration: 'none',
    color: 'grey'
  },
  tables: {
    marginBottom: 50,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 65, marginRight: 5, marginBottom: 50
    }
  },

});
const date = new Date().toISOString().split("T")[0]
class Invoice extends Component {

  constructor() {
    super()
    this.state = {
      transactions: [],
      invoices: [],
      errors: '',
      deals_with: [],
      whatsapp: [],
      bill_no: '',
      date: date,
      header: '',
      customer: '',
      notdisabled: '',
    }
    this.onChange = this.onChange.bind(this)
    //    this.handleClose = this.handleClose.bind(this)
    const transactioncustomer = {
      customer_id: localStorage.getItem('transid'),
      cookies: localStorage.getItem('jwt')
    }

    axios.post($links.customerprofile, transactioncustomer)

      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({
            transactions: res.data.message, header: res.data.header, customer: res.data.customer,
            invoices: res.data.invoice, notdisabled: res.data.disabled, deals_with: res.data.customer.deals_with,
            whatsapp: res.data.customer.whatsapp,
          })
        }
        else {
          alert(res.data.message)
        }
      })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    const { classes } = this.props
    var { customer = [] } = this.state
    var { invoices = [] } = this.state
    var { transactions = [] } = this.state
    return (
      <div>
        <Button variant="contained" color="primary" onClick={() => this.props.history.push('/addinvoice')} className={classes.button} >Add Invoice</Button>
        <Container>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
            <Link color="inherit" className={classes.link} to="/addinvoice">
              Add Invoice</Link>
            <Link color="inherit" className={classes.link} to="/invoice">
              View Invoices</Link>
            <Link color="inherit" className={classes.link} to="/addcustomer">
              Add Customer</Link>
            <Link color="inherit" className={classes.link} to="/customer">
              View Customers</Link>
          </Breadcrumbs>
          <Paper elevation={3} style={{ marginBottom: 40 }}>
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12}>
                <Typography align="left" variant="h4" style={{ marginLeft: 45 }}>Customer Details</Typography>
                <Divider style={{ marginTop: 10 }} />
              </Grid>
              <Grid item xs={12} sm={5} style={{ marginLeft: 50 }}>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Entity Name: </b>{customer.entity_name}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Person Name: </b>{customer.person_name}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography} ><b>Mobile Number 1: </b>{customer.mobile1}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Mobile Number 2: </b>{customer.mobile2}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>GST No: </b>{customer.gstno}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Email: </b>{customer.email}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Customer Type: </b>{customer.type}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Grade: </b>{customer.grade}</Typography>
              </Grid>
              <Grid item xs={12} sm={5} style={{ marginLeft: 50 }}>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Address: </b>{customer.address}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>State: </b>{customer.state}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>District: </b>{customer.district}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>City: </b>{customer.city}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Postal Code: </b>{customer.postal_code}</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>Deals With: </b> {this.state.deals_with.map((deals_with, index) => (
                  <Typography display="inline" key={deals_with}>{deals_with}{index + 1 !== this.state.deals_with.length ? ', ' : ''}</Typography>

                ))}.</Typography>
                <Typography align="left" variant="body2" className={classes.Typography}><b>WhatsApp Numbers: </b> {this.state.whatsapp.map((whatsapp, index) => (
                  <Typography display="inline" key={whatsapp}>{whatsapp}{index + 1 !== this.state.whatsapp.length ? ', ' : ''}</Typography>

                ))}.</Typography>

                <Typography align="left" variant="body2" className={classes.Typography}><b>Balance: </b>₹{Math.round(customer.balance)} (Amount Due)</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <div className={classes.tables}>
          <MaterialTable
            title={"All Invoices of " + this.state.header}
            columns={[
              { title: 'Invoice ID', field: 'invoice_id', headerStyle: nowrap, hidden: true },
              { title: 'Invoice Number', field: 'invoice_number', editable: "never", cellStyle: nowrap, headerStyle: nowrap },
              { title: 'Billing Address', field: 'address', cellStyle: { whiteSpace: 'nowrap', maxWidth: 300, overflow: 'hidden' } },
              { title: 'Phone', field: 'phone' },
              { title: 'Invoice Date', field: 'invoice_date', headerStyle: nowrap, cellStyle: nowrap },
              { title: 'Created', field: 'created' },
              { title: 'Grand Total', field: 'grand_total', headerStyle: nowrap },
              { title: 'Cancelled', field: 'cancelled', lookup: { 0: "No", 1: "Yes" }, headerStyle: nowrap },
              { title: 'Cancelled By', field: 'cancelled_by', headerStyle: nowrap, emptyValue: '--' },
            ]}
            data={invoices.map(invoices => (
              {
                invoice_id: invoices.invoice_id, invoice_number: invoices.invoice_number, invoice_date: invoices.invoice_date,
                state: invoices.billto[2], grand_total: Math.round(invoices.grand_total), customer_name: invoices.billto[0], address: invoices.billto[1],
                phone: invoices.billto[4], gstno: invoices.billto[6], created: invoices.created, cancelled_by: invoices.cancelled_by, cancelled: invoices.cancelled
              }))
            }
            actions={[
              {
                icon: 'visibility',
                tooltip: 'Download Invoice',
                onClick: (event, rowData) => {
                  localStorage.setItem('generateinvoiceid', rowData.invoice_number);
                  const win = window.open("/generateinvoice", "_blank");
                  win.focus();
                }
              }
            ]}
            options={{
              actionsColumnIndex: 9,
              sorting: true,
              padding: "dense",
              exportButton: true,
              pageSize: 10,

            }}
          />

          <MaterialTable style={{ marginTop: 20 }}
            title={"Transactions to " + this.state.header}
            columns={[
              { title: 'ID', field: 'id', headerStyle: nowrap, editable: "never" },
              { title: 'Transaction ID', field: 'transaction_id', cellStyle: nowrap, headerStyle: nowrap },
              {
                title: 'Transaction Mode', field: 'transaction_mode', headerStyle: nowrap, lookup: {
                  "Cash": "Cash", "UPI": "UPI", "Cheque": "Cheque", "Net Banking": "Net Banking", "Other": "Other", "Coupon": "Coupon"
                }
              },
              { title: 'Transaction Type', field: 'transaction_type', lookup: { "+": "Credit", "-": "Debit" }, cellStyle: nowrap, headerStyle: nowrap },
              { title: 'Transaction Date', field: 'transaction_date', type: "datetime", cellStyle: nowrap, headerStyle: nowrap },
              { title: 'Amount', field: 'amount', type: 'numeric' },
              { title: 'Balance', field: 'balance', headerStyle: nowrap, type: 'numeric', editable: "never" },
              { title: 'Remark', field: 'remark', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 250, overflow: 'hidden' } }

            ]}
            data={transactions.map(transactions => (
              {
                id: transactions.id, transaction_id: transactions.transaction_id, transaction_mode: transactions.transaction_mode,
                transaction_type: transactions.transaction_type, amount: transactions.amount, transaction_date: transactions.transaction_date,
                balance: Math.round(transactions.balance), remark: transactions.remark
              }))
            }
            editable={{
              isEditable: rowData => rowData.id === this.state.notdisabled,
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    this.setState({ transactions: [...transactions, newData] })
                    const newtransactions = {
                      transaction_id: newData.transaction_id,
                      customer_id: customer.customer_id,
                      transaction_mode: newData.transaction_mode,
                      transaction_type: newData.transaction_type,
                      amount: newData.amount,
                      transaction_date: newData.transaction_date,
                      balance: newData.balance,
                      remark: newData.remark,
                      cookies: localStorage.getItem('jwt')
                    }
                    if (newtransactions.transaction_date) {
                      newtransactions.transaction_date = newtransactions.transaction_date.toLocaleString()
                    }
                    axios.post($links.addtransaction, newtransactions)
                      .then(res => {
                        if (res.data.errors === false) {
                          window.location.reload(false)
                        } else {
                          this.setState({ errors: res.data.message })
                          alert(this.state.errors)
                          window.location.reload(false)
                        }
                      })
                    resolve();
                  }, 500)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...transactions];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    this.setState({ transactions: [...dataUpdate] });
                    const newtransactions = {
                      id: newData.id,
                      transaction_id: newData.transaction_id,
                      customer_id: customer.customer_id,
                      transaction_mode: newData.transaction_mode,
                      transaction_type: newData.transaction_type,
                      amount: newData.amount,
                      transaction_date: newData.transaction_date,
                      balance: newData.balance,
                      remark: newData.remark,
                      cookies: localStorage.getItem('jwt')
                    }
                    if (newtransactions.transaction_date) {
                      newtransactions.transaction_date = newtransactions.transaction_date.toLocaleString()
                    }
                    axios.post($links.updatetransaction, newtransactions)
                      .then(res => {
                        if (res.data.errors === false) {
                          window.location.reload(false)
                        } else {
                          this.setState({ errors: res.data.message })
                          alert(this.state.errors)
                          window.location.reload(false)
                        }
                      })
                    resolve();
                  }, 500)
                }),
            }}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              padding: "dense",
              exportButton: true,
              pageSize: 10,
              rowStyle: rowData => {

                if (rowData.transaction_type === '+') {
                  return { backgroundColor: '#f5e6e6' };
                }
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Invoice)