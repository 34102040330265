import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import axios from 'axios'
import { Breadcrumbs, Button, Container } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import $links from './variable';
const useStyles = (theme) => ({

  button: {
    margin: theme.spacing(4, 0, 2),

  },
  link: {
    textDecoration: 'none',
    color: 'grey'
  }
});

class Invoice extends Component {

  constructor() {
    super()
    this.state = {
      customer: [],
      customer_id: '',
      errors: '',

    }
    axios.post($links.customerdisplay, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          window.location.replace('/')
        }
        else if (res.data.errors) {
          alert(res.data.message)
        } else {
          this.setState({ customer: res.data.message })
        }
      })
  }

  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    var { customer = [] } = this.state
    const { classes } = this.props
    return (
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" color="primary" component={NavLink} to='/addcustomer' className={classes.button} >Add Customer</Button>
        <Container>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
            <Link color="inherit" className={classes.link} to="/addinvoice">
              Add Invoice</Link>
            <Link color="inherit" className={classes.link} to="/invoice">
              View Invoices</Link>
            <Link color="inherit" className={classes.link} to="/addcustomer">
              Add Customer</Link>
          </Breadcrumbs>
          <MaterialTable
            title="Customers"
            columns={[
              { title: 'Customer ID', field: 'customer_id', editable: "never", headerStyle: nowrap, hidden: true },
              { title: 'Customer Type', field: 'type', headerStyle: nowrap },
              { title: 'Entity Name', field: 'entity_name', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 200, overflow: 'hidden' } },
              { title: 'Person Name', field: 'person_name', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 200, overflow: 'hidden' } },
              { title: 'Mobile Number', field: 'mobile', headerStyle: nowrap },
              { title: 'Email ID', field: 'email', headerStyle: nowrap, hidden: true },
              { title: 'Grade', field: 'grade', headerStyle: nowrap, lookup: { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D' } },
              { title: 'Address', field: 'address', cellStyle: { whiteSpace: 'nowrap', maxWidth: 150, overflow: 'hidden' } },
              { title: 'Created', field: 'create_at', editable: "never", headerStyle: nowrap },
              { title: 'Postal Code', field: 'postal_code', hidden: true },
              { title: 'district', field: 'district', hidden: true },
            ]}
            data={customer.map(customer => (
              {
                customer_id: customer.customer_id, entity_name: customer.entity_name, person_name: customer.person_name,
                mobile: customer.mobile1 + ' ' + customer.mobile2, email: customer.email, address: customer.city + ', ' + customer.state,
                gstno: customer.gstno, create_at: customer.create_at, type: customer.type, grade: customer.grade
              }))
            }
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View Profile',
                onClick: (event, rowData) => {
                  localStorage.setItem('transid', rowData.customer_id);
                  this.props.history.push('/profile')
                }
              },
              {
                icon: 'edit',
                tooltip: 'Edit Customer',
                onClick: (event, rowData) => {
                  localStorage.setItem('transid', rowData.customer_id);
                  this.props.history.push('/editcustomer')
                }
              }
            ]}
            options={{
              actionsColumnIndex: 9,
              sorting: true,
              pageSize: 10,
              exportButton: true,
              padding: 'dense'
            }}
          />

        </Container>


      </div>

    );
  }
}

export default withStyles(useStyles)(Invoice)