import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import {
  Grid, Container,
  Typography, Button, DialogActions, Checkbox, FormControlLabel, Slider, TextField, Dialog, DialogContent, InputAdornment, Tooltip, FormControl, InputLabel, Select, Snackbar, Backdrop, CircularProgress, Slide, DialogTitle, Paper, IconButton, Box,
} from '@material-ui/core';
import axios from 'axios';
import $links from './variable'
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Draggable from 'react-draggable';
import { Close, Phone, WhatsApp } from '@material-ui/icons';

const chancesmarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];
const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = (theme) => ({
  checkboxes: {
    marginTop: theme.spacing(1),
  },
  root: {
    marginTop: 60,
  },
  dialogTitle: {

  },
  Button: {
    position: 'fixed',
    top: '50%',
    right: 0,
    zIndex: 1000
  },
  dialog: {
    alignItems: 'baseline'
  },
  Card: {
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.4)',
    borderRadius: 5
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
const today = new Date()
class EnquiryDashboard extends Component {

  constructor() {
    super()
    this.state = {
      enquiry_id: '',
      all: [],
      opencircle: true,
      checkeditems: [false, false, false],
      filterproducts: [],
      entity_name: '',
      person_name: '',
      mobile1: '',
      filterchances: [0, 100],
      mobile2: '',
      gstno: '',
      email: '',
      address: '',
      postal_code: '',
      alldatas: [],
      state: 0,
      district: '',
      city: '',
      errors: '',
      customer_type: '',
      grade: 'A',
      deals_with: [],
      openerror: false,
      opensuccess: false,
      opendialog: false,
      whatsapp1: false,
      whatsapp2: false,
      chancesofconversion: 50,
      unit: 1,
      products: [],
      product_id: 1,
      product: null,
      lead_source: '',
      openfilter: false,
      beginning_date: today,
      last_date: today,
      follow_date: today,
      filterfollowdate: '',
      dates: '',
      remark: '',
      reason: '',
      whatappschecked: false,
      phonechecked: false,
      followchecked: false,
      notfollowupdone: false,
      addedby: [],
      res_customertype: [],
      res_customerdealswith: [],
      res_leadsource: [],
      create_at: '',
      success: ''
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this)
    axios.post($links.allenquiries, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({
            all: res.data.all, opencircle: false, alldatas: res.data.all
          })
        }
        else {
          alert(res.data.message)
        }
      })
    axios.post($links.enquiry, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.errors === true) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({ products: res.data.message, res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith, res_leadsource: res.data.leadsource, customer_type: res.data.type, lead_source: res.data.lead_source })
        } else {
          this.setState({ errors: res.data.message })
        }
      })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleClose() {
    this.setState({ opendialog: false, openfilter: false })
  }
  handleCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
    if (e.target.name === 'whatappschecked' && e.target.checked) {
      var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
      var timer = setInterval(function () {
        win.close()
        clearInterval(timer);
      }, 3000);
    }
    else if (e.target.name === 'phonechecked' && e.target.checked) {
      window.open("tel:" + this.state.mobile1);
    }
  };
  handleCheckboxFilter(e) {
    var names = e.target.name
    var checkeditem = this.state.checkeditems
    if (names === 'whatsappdone') {
      checkeditem[0] = e.target.checked
      this.setState({ checkeditems: checkeditem })
    } else if (names === 'phonedone') {
      checkeditem[1] = e.target.checked
      this.setState({ checkeditems: checkeditem })
    } else if (names === 'notfollowupdone') {
      this.setState({ notfollowupdone: e.target.checked })
    }
  };
  onFilterChange = async () => {
    this.setState({ opencircle: true })
    const filters = {
      dates: this.state.dates,
      filterfollowdate: this.state.filterfollowdate,
      filterproducts: this.state.filterproducts,
      checkeditems: this.state.checkeditems,
      notfollowupdone: this.state.notfollowupdone,
      filterchances: this.state.filterchances
    }
    var display = this.state.alldatas
    if (filters.dates) {
      await axios.post($links.filterenquiry, filters)
        .then(res => {
          if (res.data.errors === false) {
            display = res.data.message
          } else {
            this.setState({ errors: res.data.message, open: true })
          }
        })
    }
    if (filters.filterfollowdate)
      display = display.filter(function (el) { return el.follow_date === new Date(filters.filterfollowdate).toLocaleDateString() })
    if (filters.notfollowupdone) {
      var notfollowupdone = display.filter(function (el) { return el.checked[2] === false })
      var date = new Date()
      date.setDate(date.getDate() - 1)
      display = notfollowupdone.filter(function (el) { return new Date(el.follow_date) < date })
    }
    if (filters.filterproducts.length !== 0) {
      var i = 0
      var products = []
      while (i < filters.filterproducts.length) {
        // eslint-disable-next-line no-loop-func
        var products1 = display.filter(function (el) { return el.product_id === filters.filterproducts[i] })
        products = products.concat(products1)
        i++
      }
      display = products
    }
    if (filters.checkeditems[0]) {
      display = display.filter(function (el) { return el.checked[0] === false })
    }
    if (filters.checkeditems[1]) {
      display = display.filter(function (el) { return el.checked[1] === false })
    }
    if (filters.filterchances[0] !== 0 && filters.filterchances[1] !== 100) {
      display = display.filter(function (el) { return el.chancesofconversion >= filters.filterchances[0] && el.chancesofconversion <= filters.filterchances[1] })
    }

    this.setState({ all: display, opencircle: false })
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const enquiry_details = {
      enquiry_id: this.state.enquiry_id,
      entity_name: this.state.entity_name,
      person_name: this.state.person_name,
      mobile1: this.state.mobile1,
      mobile2: this.state.mobile2,
      gstno: this.state.gstno,
      email: this.state.email,
      address: this.state.address,
      postal_code: this.state.postal_code,
      state: this.state.state,
      district: this.state.district,
      city: this.state.city,
      customer_type: this.state.customer_type,
      grade: this.state.grade,
      deals_with: this.state.deals_with,
      whatsapp1: this.state.whatsapp1,
      whatsapp2: this.state.whatsapp2,
      chancesofconversion: this.state.chancesofconversion,
      unit: this.state.unit,
      reason: this.state.reason,
      product_id: this.state.product_id,
      lead_source: this.state.lead_source,
      beginning_date: this.state.beginning_date,
      last_date: this.state.last_date,
      follow_date: this.state.follow_date,
      remark: this.state.remark,
      checked: [this.state.whatappschecked,
      this.state.phonechecked,
      this.state.followchecked],
      cookies: localStorage.getItem('jwt')
    }
    await axios.post($links.updateenquiry, enquiry_details)
      .then(res => {
        if (res.data.errors === false) {
          axios.post($links.allenquiries, { cookies: localStorage.getItem('jwt') })
            .then(res => {
              if (res.data.errors === false) {
                this.setState({
                  all: res.data.all, alldatas: res.data.all
                })
              }
            })
          this.setState({ opensuccess: true, success: res.data.message, opendialog: false })
        } else {
          this.setState({ errors: res.data.message, open: true })
        }
      })
  }
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    const { classes } = this.props
    var { all = [] } = this.state
    var { products = [] } = this.state
    var { res_customertype = [] } = this.state
    var { res_customerdealswith = [] } = this.state
    const { res_leadsource = [] } = this.state
    const addedby = this.state.addedby
    return (
      <div className={classes.root}>
        <Snackbar open={this.state.openerror} autoHideDuration={4000} onClose={(e, reason) => {
          if (reason === 'clickaway') {
            return
          }
          this.setState({ openerror: false })
        }}>
          <Alert onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return
            }
            this.setState({ openerror: false })
          }} severity="error">
            {this.state.errors}
          </Alert>
        </Snackbar>

        <Snackbar open={this.state.opensuccess} autoHideDuration={4000} onClose={(e, reason) => {
          if (reason === 'clickaway') {
            return
          }
          this.setState({ opensuccess: false })
        }}>
          <Alert onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return
            }
            this.setState({ opensuccess: false })
          }} severity="success">
            {this.state.success}
          </Alert>
        </Snackbar>
        <Dialog
          open={this.state.openfilter}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          keepMounted
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} >FILTER OPTIONS</Box>
              <Box>
                <IconButton onClick={this.handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3} >
                <TextField
                  id="date"
                  label="Select Date"
                  type="date"
                  name="dates"
                  fullWidth
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.dates}
                  onChange={(e) => {
                    this.setState({ filterfollowdate: '', dates: e.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} >
                <TextField
                  id="date"
                  label="Select Follow Up Date"
                  type="date"
                  name="filterfollowdate"
                  fullWidth
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.filterfollowdate}
                  onChange={(e) => {
                    this.setState({ filterfollowdate: e.target.value, dates: '' })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  limitTags={2}
                  options={products}
                  getOptionLabel={(option) => option.model}
                  onChange={async (e, newValue) => {
                    if (!newValue[0]) {
                      this.setState({ filterproducts: newValue })
                    } else {
                      var i = 0
                      var product_id = []
                      while (i < newValue.length) {
                        product_id.push(newValue[i].product_id)
                        i++
                      }
                      this.setState({ filterproducts: product_id })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Product" fullWidth placeholder="Select Product" />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={4} className={classes.checkboxes}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.checkeditems[0]} onChange={this.handleCheckboxFilter} name="whatsappdone" />}
                  label="WhatsApp Not Done"
                />
              </Grid>
              <Grid item xs={6} sm={4} className={classes.checkboxes}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.checkeditems[1]} onChange={this.handleCheckboxFilter} name="phonedone" />}
                  label="Phone Not Done"
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.checkboxes}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.notfollowupdone} onChange={this.handleCheckboxFilter} name="notfollowupdone" />}
                  label="Follow Up Not Done on Time"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography id="range-slider" gutterBottom>Chances of conversion Range </Typography>
                <Slider
                  marks={chancesmarks}
                  value={this.state.filterchances}
                  onChange={(e, newvalue) => {
                    if (newvalue[0] !== newvalue[1]) {
                      this.setState({ filterchances: newvalue })
                    }
                  }}
                  step={10}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => {
              this.setState({ openfilter: false })
              this.onFilterChange()
            }} color="primary">
              Apply and close
          </Button>
            <Button onClick={this.onFilterChange} color="primary">
              Apply
          </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.opendialog} onClose={this.handleClose} fullWidth maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogContent style={{ maxHeight: 900 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="outline-flexible"
                  label="Entity Name"
                  placeholder="Enter Entity Name"
                  variant="outlined"
                  fullWidth
                  name="entity_name"
                  value={this.state.entity_name}
                  autoFocus
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Person Name"
                  placeholder="Enter Name"
                  variant="outlined"
                  fullWidth
                  name="person_name"
                  value={this.state.person_name}
                  onChange={this.onChange}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Mobile Number 1"
                  placeholder="Mobile Number 1"
                  fullWidth
                  name="mobile1"
                  value={this.state.mobile1}
                  onChange={this.onChange}
                  id="outlined-start-adornment"
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start" >
                        <Tooltip title="Set this as Whatsapp Number">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="whatsapp1"
                                color="primary"
                                checked={this.state.whatsapp1}
                                onChange={this.handleCheckbox}
                              />
                            }
                          />
                        </Tooltip>
                      </InputAdornment>,
                    type: "Number"
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Mobile Number 2"
                  placeholder="(Optional)"
                  fullWidth
                  name="mobile2"
                  value={this.state.mobile2}
                  onChange={this.onChange}
                  id="outlined-start-adornment1"
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start" >
                        <Tooltip title="Set this as Whatsapp Number">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="whatsapp2"
                                color="primary"
                                checked={this.state.whatsapp2}
                                onChange={this.handleCheckbox}
                              />
                            }
                          />
                        </Tooltip>
                      </InputAdornment>,
                    type: "Number"
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="GSTN"
                  placeholder="GST Number"
                  variant="outlined"
                  fullWidth
                  name="gstno"
                  value={this.state.gstno}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  placeholder="Enter Address"
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Postal Code"
                  placeholder="6 digit number"
                  variant="outlined"
                  fullWidth
                  name="postal_code"
                  value={this.state.postal_code}
                  onChange={this.onChange}
                  inputProps={{ maxLength: 6 }}
                  onInput={(e) => {
                    if (e.target.value) {
                      if (e.target.value.length === 6) {
                        const postal = {
                          postal_code: e.target.value
                        }
                        axios.post($links.postal_code, postal)
                          .then(res => {

                            if (res.data.errors) {
                              this.setState({ errors: res.data.message, open: true })
                            }
                            else {
                              this.setState({ state: res.data.state, district: res.data.district, city: res.data.city })
                            }
                          })
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.state}
                    onChange={this.onChange}
                    label="State"
                    name="state"
                  >
                    <option value="0" disabled>SELECT STATE</option>
                    <option value="ANDHRA PRADESH">Andhra Pradesh</option>
                    <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                    <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                    <option value="ASSAM">Assam</option>
                    <option value="BIHAR">Bihar</option>
                    <option value="CHANDIGARH">Chandigarh</option>
                    <option value="CHATTISGARH">Chhattisgarh</option>
                    <option value="DADRA & NAGAR HAVELI">Dadra and Nagar Haveli</option>
                    <option value="DAMAN AND DIU">Daman and Diu</option>
                    <option value="DELHI">Delhi</option>
                    <option value="LAKSHADWEEP">Lakshadweep</option>
                    <option value="PUDUCHERRY">Puducherry</option>
                    <option value="GOA">Goa</option>
                    <option value="GUJARAT">Gujarat</option>
                    <option value="HARYANA">Haryana</option>
                    <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                    <option value="JAMMU AND KASHMIR">Jammu and Kashmir</option>
                    <option value="JHARKHAND">Jharkhand</option>
                    <option value="KARNATAKA">Karnataka</option>
                    <option value="KERALA">Kerala</option>
                    <option value="MADHYA PRADESH">Madhya Pradesh</option>
                    <option value="MAHARASHTRA">Maharashtra</option>
                    <option value="MANIPUR">Manipur</option>
                    <option value="MEGHALAYA">Meghalaya</option>
                    <option value="MIZORAM">Mizoram</option>
                    <option value="NAGALAND">Nagaland</option>
                    <option value="ODISHA">Odisha</option>
                    <option value="PUNJAB">Punjab</option>
                    <option value="RAJASTHAN">Rajasthan</option>
                    <option value="SIKKIM">Sikkim</option>
                    <option value="TAMIL NADU">Tamil Nadu</option>
                    <option value="TELANGANA">Telangana</option>
                    <option value="TRIPURA">Tripura</option>
                    <option value="UTTAR PRADESH">Uttar Pradesh</option>
                    <option value="UTTARAKHAND">Uttarakhand</option>
                    <option value="WEST BENGAL">West Bengal</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="City"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={this.state.city}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="District"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={this.state.district}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Customer Type</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.customer_type}
                    onChange={this.onChange}
                    label="Select Custom"
                    name="customer_type"
                  >
                    <option disabled>Select Customer Type</option>
                    {res_customertype.map(res_customertype => (
                      <option key={res_customertype} value={res_customertype}>{res_customertype}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Select Grade</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.grade}
                    onChange={this.onChange}
                    label="Select Custo"
                    name="grade"
                  >
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Units"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="unit"
                  InputProps={{
                    type: "Number"
                  }}
                  value={this.state.unit}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="combo-box-demo"
                  options={products}
                  value={this.state.product}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      this.setState({ product_id: newValue.product_id });
                    }
                  }}
                  getOptionLabel={(option) => "Product Name: " + option.name_of_good + " | Model: " + option.model}
                  name="name"
                  renderInput={(params) => <TextField {...params} label="Search Product" variant="outlined" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={res_customerdealswith}
                  fullWidth
                  value={this.state.deals_with}
                  getOptionLabel={(option) => option}
                  onChange={(e, newValue) => {
                    this.setState({ deals_with: newValue })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Customer Deals With" fullWidth placeholder="Select Dealer deals with" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Lead Source</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.lead_source}
                    onChange={this.onChange}
                    label="Lead Source"
                    name="lead_source"
                  >
                    <option disabled>Select Lead Source</option>
                    {res_leadsource.map(res_leadsource => (
                      <option key={res_leadsource} value={res_leadsource}>{res_leadsource}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  <KeyboardDatePicker
                    fullWidth
                    format="dd/MM/yyyy"
                    margin="dense"
                    id="date-picker-inline1"
                    name="beginning_date"
                    label="Beginning"
                    value={this.state.beginning_date}
                    onChange={(date) => {
                      this.setState({ beginning_date: date });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        format="dd/MM/yyyy"
                        margin="dense"
                        id="date-picker-inline3"
                        name="last_date"
                        label="Last Contact"
                        value={this.state.last_date}
                        onChange={(date) => {
                          this.setState({ last_date: date });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        format="dd/MM/yyyy"
                        margin="dense"
                        id="date-picker-inline2"
                        name="follow_date"
                        label="Follow Up"
                        value={this.state.follow_date}
                        onChange={(date) => {
                          this.setState({ follow_date: date });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.whatappschecked}
                            onChange={this.handleCheckbox}
                            name="whatappschecked"
                            color="primary"
                          />
                        }
                        label="WhatsApp Send"
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.phonechecked}
                            onChange={this.handleCheckbox}
                            name="phonechecked"
                            color="primary"
                          />
                        }
                        label="Phone Call Done"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.followchecked}
                            onChange={this.handleCheckbox}
                            name="followchecked"
                            color="primary"
                          />
                        }
                        label="Follow Up Done"
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Remarks On Enquiry"
                  multiline
                  rowsMax={5}
                  placeholder="Remarks"
                  variant="outlined"
                  fullWidth
                  name="remark"
                  value={this.state.remark}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography id="discrete-slider" align="left" gutterBottom><b>Chances Of Conversion :</b></Typography>
                <Slider
                  value={this.state.chancesofconversion}
                  onChange={(e, newValue) => {
                    this.setState({ chancesofconversion: newValue })
                  }}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  min={0}
                  max={100}
                  marks={marks}
                />
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              {this.state.followchecked ?
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Reason for Follow Up Done"
                    multiline
                    rows={4}
                    placeholder="Reason"
                    variant="outlined"
                    fullWidth
                    name="reason"
                    value={this.state.reason}
                    onChange={this.onChange}
                  />
                </Grid> : ''}
              <Grid item xs={12} sm={5} style={{ marginTop: 30 }}>
                <Typography><b>Added/Updated By:</b> {addedby[0] + ', ' + addedby[1]}</Typography>
              </Grid>
              <Grid item xs={12} sm={1} style={{ marginTop: 30 }}>
                <Tooltip title="Send WhatsApp">
                  <IconButton onClick={() => {
                    var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
                    var timer = setInterval(function () {
                      win.close()
                      clearInterval(timer);
                    }, 3000);
                  }} style={{ backgroundColor: '#25D366', color: 'white' }} size="small">
                    <WhatsApp fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Call">
                  <IconButton color="primary" size="small" onClick={() => {
                    var win1 = window.open("tel:" + this.state.mobile1)
                    var timer1 = setInterval(function () {
                      win1.close()
                      clearInterval(timer1);
                    }, 30000);
                  }}>
                    <Phone fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSubmit} color="primary">Update enquiry</Button>
            <Button onClick={this.handleClose} color="primary"> Close</Button>
          </DialogActions>
        </Dialog>
        <Button variant="contained" color="secondary" className={classes.Button}
          onClick={() => {
            this.setState({ openfilter: true })
          }}
        >Filter</Button>
        <Container>
          <MaterialTable style={{ borderRadius: 15, borderBottom: '3px solid #f0bc11', borderTop: '3px solid #4cc42d', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.4)' }}
            title="All Enquiries"
            columns={[
              { title: 'ID', field: 'enquiry_id', cellStyle: { width: '1%' } },
              { title: 'Name', field: 'entity_name', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 200 } },
              { title: 'District', field: 'district', cellStyle: nowrap, headerStyle: nowrap },
              { title: 'Created', field: 'create_at', headerStyle: nowrap, cellStyle: nowrap },
              { title: 'Mobile Number', field: 'mobile1', headerStyle: nowrap, cellStyle: nowrap },
              { title: 'Status', field: 'status', lookup: { 0: "Opened", 1: "Closed" } },
              {
                title: 'Other Actions', field: 'others', headerStyle: nowrap, cellStyle: { width: '1%' }, render: rowData => (
                  <div>
                    <Tooltip title="Send WhatsApp">
                      <IconButton onClick={() => {
                        var win = window.open("https://api.whatsapp.com/send?phone=91" + rowData.mobile);
                        var timer = setInterval(function () {
                          win.close()
                          clearInterval(timer);
                        }, 3000);
                      }} style={{ backgroundColor: '#25D366', color: 'white' }} size="small">
                        <WhatsApp fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Call">
                      <IconButton color="primary" size="small" onClick={() => {
                        var win1 = window.open("tel:" + rowData.mobile)
                        var timer1 = setInterval(function () {
                          win1.close()
                          clearInterval(timer1);
                        }, 30000);
                      }}>
                        <Phone fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              },
            ]}
            data={all.map(alldata => (
              {
                enquiry_id: alldata.enquiry_id, entity_name: [alldata.entity_name, alldata.person_name].filter(Boolean).join(", "), district: alldata.district,
                status: alldata.status, create_at: alldata.create_at, mobile1: alldata.mobile[1] ? alldata.mobile[0] + ', ' + alldata.mobile[1] : alldata.mobile[0],
                mobile: alldata.mobile[0]
              }))}
            actions={[
              {
                icon: 'visibility',
                tooltip: 'View Enquiry',
                onClick: async (event, rowData) => {
                  var { alldatas = [] } = this.state
                  var objIndex = alldatas.findIndex((obj => obj.enquiry_id === rowData.enquiry_id));
                  var newdata = alldatas[objIndex]
                  this.setState({
                    enquiry_id: rowData.enquiry_id,
                    entity_name: newdata.entity_name,
                    person_name: newdata.person_name,
                    mobile1: newdata.mobile[0],
                    mobile2: newdata.mobile[1],
                    gstno: newdata.gstno,
                    email: newdata.email,
                    address: newdata.address,
                    postal_code: newdata.postal_code,
                    state: newdata.state,
                    district: newdata.district,
                    city: newdata.city,
                    customer_type: newdata.customer_type,
                    grade: newdata.grade,
                    deals_with: newdata.deals_with,
                    whatsapp1: Boolean(newdata.whatsapp[0]),
                    whatsapp2: Boolean(newdata.whatsapp[1]),
                    chancesofconversion: newdata.chancesofconversion,
                    unit: newdata.unit,
                    product_id: newdata.product_id,
                    lead_source: newdata.lead_source,
                    beginning_date: newdata.beginning_date,
                    last_date: newdata.last_date,
                    follow_date: newdata.follow_date,
                    create_at: newdata.create_at,
                    remark: newdata.remark,
                    reason: newdata.reason === undefined ? '' : newdata.reason,
                    whatappschecked: newdata.checked[0],
                    phonechecked: newdata.checked[1],
                    followchecked: newdata.checked[2],
                    opendialog: true
                  })
                  await axios.post($links.viewenquiry, { cookies: localStorage.getItem('jwt'), enquiry_id: rowData.enquiry_id })
                    .then(res => {

                      if (res.data.errors === false) {
                        this.setState({
                          products: res.data.products,
                          product: res.data.products[res.data.index],
                          addedby: res.data.addedby,
                        })
                      }
                      else {
                        alert(res.data.message)
                      }
                    })
                }
              },
            ]}
            options={{
              padding: 'dense',
              pageSize: 20,
              actionsColumnIndex: -1,
              draggable: false,
              rowStyle: rowData => {

                if (rowData.enquiry_id === this.state.enquiry_id) {
                  return { backgroundColor: '#ebebeb' };
                } else if (rowData.status === 0) {
                  return { backgroundColor: '#ebe65e' };
                } else if (rowData.status === 1) {
                  return { backgroundColor: '#91e874' };
                }
              },
            }}
          />
          <br />
        </Container>
        <Backdrop className={classes.backdrop} open={this.state.opencircle}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div >
    );
  }
}
export default withStyles(useStyles)(EnquiryDashboard)
