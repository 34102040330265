import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EventNoteIcon from '@material-ui/icons/EventNote';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BallotIcon from '@material-ui/icons/Ballot';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { NavLink } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/Person';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Button from '@material-ui/core/Button';
import { Tooltip, Collapse, Menu, MenuItem, Link } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { ExitToApp, ExpandLess, ExpandMore, PeopleAlt } from '@material-ui/icons';
import axios from 'axios';
import $links from '../variable';
const drawerWidth = 240;

const useStyles = ((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  Typography: {
    marginTop: 8,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10
  },
  links: {
    fontSize: 16,
    marginRight: 10,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 30,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

class PersistentDrawerLeft extends Component {
  constructor() {
    super()
    this.state = {
      open: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      hidden: true,
      display: 'flex',
      anchorEl: null,
      anchorE2: null,
      anchorE3: null,
      anchorE4: null,
      permission: [],
      admin: 0
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleClick = this.handleClick.bind(this)
    axios.post($links.permissions, { cookies: localStorage.getItem('jwt') })
      .then(res => {

        if (res.data.errors === false) {
          this.setState({ permission: res.data.permission, admin: res.data.admin })
          if(!res.data.permission){
            localStorage.clear()
            alert('Something went wrong! Sign In Again')
            window.location.replace('/')
          }
        }
        else {
          alert(res.data.message)
        }
      })
  }
  handleDrawerOpen = () => {
    this.setState({ open: true, hidden: false, display: 'none' });
  };
  handleClick = (event) => {
    if (this.state.hidden) {
      this.setState({ anchorE3: event.currentTarget });
    } else {
      this.setState({ open1: !this.state.open1, open2: false, open3: false, open4: false });
    }
  };
  handleClick1 = (event) => {
    if (this.state.hidden) {
      this.setState({ anchorE2: event.currentTarget });
    } else {
      this.setState({ open2: !this.state.open2, open1: false, open3: false, open4: false });
    }
  };
  handleClick2 = (event) => {
    if (this.state.hidden) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ open3: !this.state.open3, open1: false, open2: false, open4: false });
    }
  };
  handleClick3 = (event) => {
    if (this.state.hidden) {
      this.setState({ anchorE4: event.currentTarget });
    } else {
      this.setState({ open4: !this.state.open4, open1: false, open2: false, open3: false });
    }
  };
  handleClose = () => {
    this.setState({ anchorEl: null, anchorE2: null, anchorE3: null, anchorE4: null });
  }
  handleLogout = async () => {
    localStorage.clear()
    alert("Logged out")
    window.location.replace('/')
  }
  handleDrawerClose = () => {
    this.setState({
      open: false, open1: false,
      open2: false,
      open3: false,
      open4: false,
      hidden: true,
      display: 'flex',
    });
  };
  render() {
    const { classes } = this.props
    const theme = this.props
    var { permission = [] } = this.state
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar>
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <img src="http://51.158.109.251:5000/logo.svg" alt="logo" style={{ marginRight: 40 }} width="230"></img>
            {permission.includes("invoice") ?
              <Link component={NavLink} to="/addinvoice" className={classes.links} color="textPrimary" variant="button" underline="hover">
                Add Invoice
              </Link> : ''}
            {permission.includes("customer") ?
              <Link component={NavLink} to="/addcustomer" className={classes.links} color="textPrimary" variant="button" underline="hover">
                Add customer
            </Link> : ''}
            {permission.includes("enquiry") ?
              <Link component={NavLink} to="/addenquiry" className={classes.links} color="textPrimary" variant="button" underline="hover">
                add enquiry
              </Link> : ''}
            {permission.includes("enquiry") ?
              <Link component={NavLink} to="/enquiry/allenquires" className={classes.links} color="textPrimary" variant="button" underline="hover">
                all enquiries
              </Link> : ''}
            <Typography className={classes.title} >
            </Typography>
            <Button color="default" onClick={this.handleLogout} size="large" ><b>Logout</b></Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List component="nav" aria-label="main mailbox folders">
            {permission.includes("invoice") ? <div>
              <ListItem button onClick={this.handleClick2}>
                <ListItemIcon onMouseEnter={(event) => {
                  if (!this.state.open) {
                    this.handleClick2(event)
                  }
                }}>
                  <DescriptionIcon />
                  <Typography style={{ display: this.state.display, marginLeft: -5 }} variant="caption" className={classes.Typography}>Invoice</Typography>
                </ListItemIcon>
                <ListItemText primary="Invoice" />
                {this.state.open3 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/addinvoice">
                    <ListItemText inset primary="Add Invoice" />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/invoice">
                    <ListItemText inset primary="View Invoice" />
                  </ListItem>
                </List>
              </Collapse>
            </div>
              : ''}
            {permission.includes("customer") ? <div>
              <ListItem button onClick={this.handleClick1}>
                <ListItemIcon onMouseEnter={(event) => {
                  if (!this.state.open) {
                    this.handleClick1(event)
                  }
                }}>
                  <PersonAddIcon />
                  <Typography style={{ display: this.state.display, marginLeft: -14 }} variant="caption" className={classes.Typography}>Customers</Typography>
                </ListItemIcon>
                <ListItemText primary="Customer" />
                {this.state.open2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/addcustomer">
                    <ListItemText inset primary="Add Customer" />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/customer">
                    <ListItemText inset primary="Customers" />
                  </ListItem>
                </List>
              </Collapse>
            </div>
              : ''}
            {permission.includes("product") ? <div>
              <ListItem button onClick={this.handleClick}>
                <ListItemIcon onMouseEnter={(event) => {
                  if (!this.state.open) {
                    this.handleClick(event)
                  }
                }}>
                  <BallotIcon />
                  <Typography style={{ display: this.state.display, marginLeft: -10 }} variant="caption" className={classes.Typography}>Products</Typography>
                </ListItemIcon>
                <ListItemText primary="Products" />
                {this.state.open1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.open1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button component={NavLink} to="/products" className={classes.nested}>
                    <ListItemText inset primary="Agriculture" onClick={() => {
                      localStorage.setItem('product', "Agriculture");
                    }} />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button component={NavLink} to="/products" className={classes.nested}>
                    <ListItemText inset primary="Software" onClick={() => {
                      localStorage.setItem('product', "Software");
                    }} />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button component={NavLink} to="/products" className={classes.nested}>
                    <ListItemText inset primary="Electronics" onClick={() => {
                      localStorage.setItem('product', "Electronic");
                    }} />
                  </ListItem>
                </List>
                <List component="div" disablePadding >
                  <ListItem button className={classes.nested} component={NavLink} to="/addproduct">
                    <ListItemText inset primary="Add Product" />
                  </ListItem>
                </List>
              </Collapse>
            </div>
              : ''}
            {permission.includes("enquiry") ? <div>
              <ListItem button onClick={this.handleClick3}>
                <ListItemIcon onMouseEnter={(event) => {
                  if (!this.state.open) {
                    this.handleClick3(event)
                  }
                }}>
                  <EventNoteIcon />
                  <Typography style={{ display: this.state.display, marginLeft: -16 }} variant="caption" className={classes.Typography}>Enquiry Log</Typography>
                </ListItemIcon>
                <ListItemText primary="Enquiry Logs" />
                {this.state.open4 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.open4} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/enquiry/dashboard">
                    <ListItemText inset primary="Enquiry DashBoard" />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/addenquiry">
                    <ListItemText inset primary="Add Enquiry" />
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={NavLink} to="/enquiry/indiamart">
                    <ListItemText inset primary="IndiaMart Enquiry Log" />
                  </ListItem>
                </List>
              </Collapse>
            </div>
              : ''}
            {permission.includes("invoice") ? <div>
              <ListItem button component={NavLink} to="/editshipping">
                <Tooltip placement="bottom" title="Update Shipping" >
                  <ListItemIcon>
                    <LocalShippingIcon />
                    <Typography style={{ display: this.state.display, marginLeft: -10 }} variant="caption" className={classes.Typography}>Shipping</Typography>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Update Shipping" />
              </ListItem>
            </div>
              : ''}
            {permission.includes("accounts") ? <div>
              <ListItem button component={NavLink} to="/accountmanagement">
                <Tooltip placement="bottom" title="Accounts" >
                  <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    <Typography style={{ display: this.state.display, marginLeft: -10 }} variant="caption" className={classes.Typography}>Accounts</Typography>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Accounts " />
              </ListItem>
            </div>
              : ''}
            {this.state.admin === 1 ? <div>
              <ListItem button component={NavLink} to="/admin/users">
                <ListItemIcon>
                  <PeopleAlt />
                  <Typography style={{ display: this.state.display, marginLeft: -1 }} variant="caption" className={classes.Typography}>Users</Typography>
                </ListItemIcon>
                <ListItemText primary="Display Users" />
              </ListItem>
            </div>
              : ''}
            <ListItem button onClick={this.handleLogout} >
              <ListItemIcon>
                <ExitToApp />
                <Typography style={{ display: this.state.display, marginLeft: -7 }} variant="caption" className={classes.Typography}>Log Out</Typography>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>

        </main>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          MenuListProps={{ onMouseLeave: this.handleClose }}
          style={{ top: '1%', left: 40 }}
          onClose={this.handleClose}
        >
          <MenuItem component={NavLink} to="/addinvoice">Add Invoice</MenuItem>
          <MenuItem component={NavLink} to="/invoice">View Invoices</MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorE2}
          open={Boolean(this.state.anchorE2)}
          MenuListProps={{ onMouseLeave: this.handleClose }}
          style={{ top: '1%', left: 40 }}
          onClose={this.handleClose}
        >
          <MenuItem component={NavLink} to="/addcustomer">Add Customer</MenuItem>
          <MenuItem component={NavLink} to="/customer">Customers</MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorE3}
          open={Boolean(this.state.anchorE3)}
          MenuListProps={{ onMouseLeave: this.handleClose }}
          style={{ top: '1%', left: 40 }}
          onClose={this.handleClose}
        >
          <MenuItem component={NavLink} to="/products" onClick={() => {
            localStorage.setItem('product', "Agriculture");
          }}>Agriculture</MenuItem>
          <MenuItem component={NavLink} to="/products" onClick={() => {
            localStorage.setItem('product', "Software");
          }}>Software</MenuItem>
          <MenuItem component={NavLink} to="/products" onClick={() => {
            localStorage.setItem('product', "Electronic");
          }}>Electronics</MenuItem>
          <MenuItem component={NavLink} to="/addproduct">Add Product</MenuItem>
        </Menu>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorE4}
          open={Boolean(this.state.anchorE4)}
          MenuListProps={{ onMouseLeave: this.handleClose }}
          style={{ top: '1%', left: 40 }}
          onClose={this.handleClose}
        >
          <MenuItem component={NavLink} to="/enquiry/dashboard">Enquiry DashBoard</MenuItem>
          <MenuItem component={NavLink} to="/addenquiry">Add Enquiry</MenuItem>
          <MenuItem component={NavLink} to="/enquiry/indiamart">IndiaMart Enquiry Log</MenuItem>
        </Menu>
      </div>
    );
  }
}
export default withStyles(useStyles)(PersistentDrawerLeft)






