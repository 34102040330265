import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, DialogContentText, Backdrop, CircularProgress, Grid, TextField, Checkbox, FormControlLabel, Select, InputLabel, FormControl, InputAdornment, Tooltip, Typography, Slider, Snackbar, IconButton,
} from '@material-ui/core';
import $links from './variable';
import MaterialTable from 'material-table';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Phone, WhatsApp } from '@material-ui/icons';
const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 100,
        label: '100%',
    },
];
const useStyles = (theme) => ({
    Invoice: {

        [theme.breakpoints.up('sm')]: {
            marginLeft: 60, marginRight: 5
        },
    },
    button: {
        margin: theme.spacing(5, 0, 0),
    },
    link: {
        textDecoration: 'none',
        color: 'grey'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    FormControl: {
        paddingTop: 40,
        paddingBottom: 10,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 50,
            paddingRight: 10,
            textAlign: 'right'
        }
    }

});
const today = new Date()

class IndiaMartEnquiry extends Component {

    constructor() {
        super()
        this.state = {
            enquiries: [],
            opensuccess: false,
            success: '',
            open: false,
            opendialog: false,
            response: '',
            color: 'black',
            opencircle: false,
            res_customertype: [],
            res_customerdealswith: [],
            res_leadsource: [],
            products: [],
            entity_name: '',
            person_name: '',
            mobile1: '',
            mobile2: '',
            gstno: '',
            email: '',
            address: '',
            postal_code: '',
            state: 0,
            district: '',
            city: '',
            errors: '',
            customer_type: '',
            grade: 'A',
            deals_with: [],
            chancesofconversion: 50,
            unit: 1,
            product_id: 1,
            lead_source: '',
            beginning_date: today,
            last_date: today,
            follow_date: today,
            remark: '',
            reason: '',
            whatappschecked: false,
            phonechecked: false,
            followchecked: false,
            whatsapp1: true,
            whatsapp2: false,
            copied: true,
            closed: true,
            openerror: false,
            id: '',

        }
        this.handleClose = this.handleClose.bind(this)
        this.handleError = this.handleError.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        axios.post($links.indiamartenquiry, { cookies: localStorage.getItem('jwt') })
            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    this.setState({ enquiries: res.data.message })
                }
                else {
                    alert(res.data.message)
                }
            })
        axios.post($links.enquiry, { cookies: localStorage.getItem('jwt') })
            .then(res => {
                if (res.data.errors === true) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    this.setState({ products: res.data.message, res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith, res_leadsource: res.data.leadsource, customer_type: res.data.type, lead_source: 'IndiaMart' })
                } else {
                    this.setState({ errors: res.data.message })

                }
            })
    }
    onChange(e) {
        console.log(this.state.lead_source)
        this.setState({ [e.target.name]: e.target.value })
    }
    handleCheckbox(e) {
        this.setState({ [e.target.name]: e.target.checked });
        if (e.target.name === 'whatappschecked' && e.target.checked) {
            var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
            var timer = setInterval(function () {
                win.close()
                clearInterval(timer);
            }, 3000);
        }
        else if (e.target.name === 'phonechecked' && e.target.checked) {
            window.open("tel:" + this.state.mobile1);
        }

    };
    handleError(e, reason) {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ openerror: false, opensuccess: false })

    }
    handleClose() {
        this.setState({ open: false, opendialog: false, color: 'black' })
    }
    onSubmit = async (e) => {
        e.preventDefault()
        if (!this.state.closed || !this.state.copied) {
            this.setState({ openerror: true, errors: "Please Select Copied and Closed" })
        } else {
            const enquiry_details = {
                id: this.state.id,
                entity_name: this.state.entity_name,
                person_name: this.state.person_name,
                mobile1: this.state.mobile1,
                mobile2: this.state.mobile2,
                gstno: this.state.gstno,
                email: this.state.email,
                address: this.state.address,
                postal_code: this.state.postal_code,
                state: this.state.state,
                district: this.state.district,
                city: this.state.city,
                customer_type: this.state.customer_type,
                grade: this.state.grade,
                deals_with: this.state.deals_with,
                whatsapp1: this.state.whatsapp1,
                whatsapp2: this.state.whatsapp2,
                chancesofconversion: this.state.chancesofconversion,
                unit: this.state.unit,
                product_id: this.state.product_id,
                lead_source: this.state.lead_source,
                beginning_date: this.state.beginning_date,
                last_date: this.state.last_date,
                follow_date: this.state.follow_date,
                remark: this.state.remark,
                reason: this.state.reason,
                checked: [this.state.whatappschecked,
                this.state.phonechecked,
                this.state.followchecked],
                cookies: localStorage.getItem('jwt'),
                update: true
            }
            await axios.post($links.addenquiry, enquiry_details)
                .then(res => {
                    if (res.data.errors === false) {
                        axios.post($links.indiamartenquiry, { cookies: localStorage.getItem('jwt') })
                            .then(res => {
                                if (res.data.errors === false) {
                                    this.setState({ enquiries: res.data.message })
                                }
                            })
                        this.setState({ opensuccess: true, success: res.data.message, opendialog: false })
                    } else {
                        this.setState({ errors: res.data.message, open: true })
                    }
                })
        }
    }
    render() {
        const nowrap = { whiteSpace: 'nowrap' }
        const { classes } = this.props
        var { enquiries = [] } = this.state
        var { products = [] } = this.state
        var { res_customertype = [] } = this.state
        var { res_customerdealswith = [] } = this.state
        var { res_leadsource = [] } = this.state
        return (
            <div>
                <Snackbar open={this.state.openerror} autoHideDuration={4000} onClose={this.handleError}>
                    <Alert onClose={this.handleError} severity="error">
                        {this.state.errors}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.opensuccess} autoHideDuration={4000} onClose={this.handleError}>
                    <Alert onClose={this.handleError} severity="success">
                        {this.state.success}
                    </Alert>
                </Snackbar>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">MESSAGE</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: this.state.color }}>
                            {this.state.response}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary"> Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.opendialog} onClose={this.handleClose} fullWidth maxWidth='lg' aria-labelledby="form-dialog-title">
                    <DialogContent style={{ maxHeight: 900 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="outline-flexible"
                                    label="Entity Name"
                                    placeholder="Enter Entity Name"
                                    variant="outlined"
                                    fullWidth
                                    name="entity_name"
                                    value={this.state.entity_name}
                                    autoFocus
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Person Name"
                                    placeholder="Enter Name"
                                    variant="outlined"
                                    fullWidth
                                    name="person_name"
                                    value={this.state.person_name}
                                    onChange={this.onChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Mobile Number 1"
                                    placeholder="Mobile Number 1"
                                    fullWidth
                                    name="mobile1"
                                    value={this.state.mobile1}
                                    onChange={this.onChange}
                                    id="outlined-start-adornment"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start" >
                                                <Tooltip title="Set this as Whatsapp Number">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="whatsapp1"
                                                                color="primary"
                                                                checked={this.state.whatsapp1}
                                                                onChange={this.handleCheckbox}
                                                            />
                                                        }
                                                    />
                                                </Tooltip>
                                            </InputAdornment>,
                                        type: "Number"
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Mobile Number 2"
                                    placeholder="(Optional)"
                                    fullWidth
                                    name="mobile2"
                                    value={this.state.mobile2}
                                    onChange={this.onChange}
                                    id="outlined-start-adornment1"
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start" >
                                                <Tooltip title="Set this as Whatsapp Number">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="whatsapp2"
                                                                color="primary"
                                                                checked={this.state.whatsapp2}
                                                                onChange={this.handleCheckbox}
                                                            />
                                                        }
                                                    />
                                                </Tooltip>
                                            </InputAdornment>,
                                        type: "Number"
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="GSTN"
                                    placeholder="GST Number"
                                    variant="outlined"
                                    fullWidth
                                    name="gstno"
                                    value={this.state.gstno}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Email Address"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Address"
                                    placeholder="Enter Address"
                                    variant="outlined"
                                    fullWidth
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Postal Code"
                                    placeholder="6 digit number"
                                    variant="outlined"
                                    fullWidth
                                    name="postal_code"
                                    value={this.state.postal_code}
                                    onChange={this.onChange}
                                    inputProps={{ maxLength: 6 }}
                                    onInput={(e) => {

                                        if (e.target.value.length === 6) {
                                            const postal = {
                                                postal_code: e.target.value
                                            }
                                            axios.post($links.postal_code, postal)
                                                .then(res => {

                                                    if (res.data.errors === true) {
                                                        this.setState({ errors: res.data.message, open: true })
                                                    }
                                                    else {
                                                        this.setState({ state: res.data.state, district: res.data.district, city: res.data.city })
                                                    }
                                                })
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                                    <Select
                                        fullWidth
                                        native
                                        value={this.state.state}
                                        onChange={this.onChange}
                                        label="State"
                                        name="state"
                                    >
                                        <option value="0" disabled>SELECT STATE</option>
                                        <option value="ANDHRA PRADESH">Andhra Pradesh</option>
                                        <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                                        <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                                        <option value="ASSAM">Assam</option>
                                        <option value="BIHAR">Bihar</option>
                                        <option value="CHANDIGARH">Chandigarh</option>
                                        <option value="CHATTISGARH">Chhattisgarh</option>
                                        <option value="DADRA & NAGAR HAVELI">Dadra and Nagar Haveli</option>
                                        <option value="DAMAN AND DIU">Daman and Diu</option>
                                        <option value="DELHI">Delhi</option>
                                        <option value="LAKSHADWEEP">Lakshadweep</option>
                                        <option value="PUDUCHERRY">Puducherry</option>
                                        <option value="GOA">Goa</option>
                                        <option value="GUJARAT">Gujarat</option>
                                        <option value="HARYANA">Haryana</option>
                                        <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                                        <option value="JAMMU AND KASHMIR">Jammu and Kashmir</option>
                                        <option value="JHARKHAND">Jharkhand</option>
                                        <option value="KARNATAKA">Karnataka</option>
                                        <option value="KERALA">Kerala</option>
                                        <option value="MADHYA PRADESH">Madhya Pradesh</option>
                                        <option value="MAHARASHTRA">Maharashtra</option>
                                        <option value="MANIPUR">Manipur</option>
                                        <option value="MEGHALAYA">Meghalaya</option>
                                        <option value="MIZORAM">Mizoram</option>
                                        <option value="NAGALAND">Nagaland</option>
                                        <option value="ODISHA">Odisha</option>
                                        <option value="PUNJAB">Punjab</option>
                                        <option value="RAJASTHAN">Rajasthan</option>
                                        <option value="SIKKIM">Sikkim</option>
                                        <option value="TAMIL NADU">Tamil Nadu</option>
                                        <option value="TELANGANA">Telangana</option>
                                        <option value="TRIPURA">Tripura</option>
                                        <option value="UTTAR PRADESH">Uttar Pradesh</option>
                                        <option value="UTTARAKHAND">Uttarakhand</option>
                                        <option value="WEST BENGAL">West Bengal</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="City"
                                    placeholder=""
                                    variant="outlined"
                                    fullWidth
                                    name="city"
                                    value={this.state.city}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="District"
                                    placeholder=""
                                    variant="outlined"
                                    fullWidth
                                    name="district"
                                    value={this.state.district}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Customer Type</InputLabel>
                                    <Select
                                        fullWidth
                                        native
                                        value={this.state.customer_type}
                                        onChange={this.onChange}
                                        label="Select Custom"
                                        name="customer_type"
                                    >
                                        <option disabled>Select Customer Type</option>
                                        {res_customertype.map(res_customertype => (
                                            <option key={res_customertype} value={res_customertype}>{res_customertype}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Select Grade</InputLabel>
                                    <Select
                                        fullWidth
                                        native
                                        value={this.state.grade}
                                        onChange={this.onChange}
                                        label="Select Custo"
                                        name="grade"
                                    >
                                        <option value='A'>A</option>
                                        <option value='B'>B</option>
                                        <option value='C'>C</option>
                                        <option value='D'>D</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Units"
                                    placeholder=""
                                    variant="outlined"
                                    fullWidth
                                    name="unit"
                                    InputProps={{
                                        type: "Number"
                                    }}
                                    value={this.state.unit}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={products}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            this.setState({ product_id: newValue.product_id });
                                        }
                                    }}
                                    getOptionLabel={(option) => "Product Name: " + option.name_of_good + " | Model: " + option.model}
                                    name="name"
                                    renderInput={(params) => <TextField {...params} label="Search Product" variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    id="multiple-limit-tags"
                                    options={res_customerdealswith}
                                    fullWidth
                                    value={this.state.deals_with}
                                    getOptionLabel={(option) => option}
                                    onChange={(e, newValue) => {
                                        this.setState({ deals_with: newValue })
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Customer Deals With" fullWidth placeholder="Select Dealer deals with" />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Lead Source</InputLabel>
                                    <Select
                                        fullWidth
                                        native
                                        value={this.state.lead_source}
                                        onChange={this.onChange}
                                        label="Lead Source"
                                        name="lead_source"
                                    >
                                        <option disabled>Select Lead Source</option>
                                        {res_leadsource.map(res_leadsource => (
                                            <option key={res_leadsource} value={res_leadsource}>{res_leadsource}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid item xs={12} sm={3}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        margin="dense"
                                        id="date-picker-inline1"
                                        name="beginning_date"
                                        label="Beginning"
                                        value={this.state.beginning_date}
                                        onChange={(date) => {
                                            this.setState({ beginning_date: date });
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                margin="dense"
                                                id="date-picker-inline3"
                                                name="last_date"
                                                label="Last Contact"
                                                value={this.state.last_date}
                                                onChange={(date) => {
                                                    this.setState({ last_date: date });
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <KeyboardDatePicker
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                margin="dense"
                                                id="date-picker-inline2"
                                                name="follow_date"
                                                label="Follow Up"
                                                value={this.state.follow_date}
                                                onChange={(date) => {
                                                    this.setState({ follow_date: date });
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.whatappschecked}
                                                        onChange={this.handleCheckbox}
                                                        name="whatappschecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="WhatsApp Send"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.phonechecked}
                                                        onChange={this.handleCheckbox}
                                                        name="phonechecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Phone Call Done"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.followchecked}
                                                        onChange={this.handleCheckbox}
                                                        name="followchecked"
                                                        color="primary"
                                                    />
                                                }
                                                label="Follow Up Done"
                                            />
                                        </Grid>
                                    </Grid></Grid>
                            </MuiPickersUtilsProvider>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Remarks On Enquiry"
                                    multiline
                                    rowsMax={6}
                                    placeholder="Remarks"
                                    variant="outlined"
                                    fullWidth
                                    name="remark"
                                    value={this.state.remark}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography id="discrete-slider" align="left" gutterBottom><b>Chances Of Conversion :</b></Typography>
                                <Slider
                                    value={this.state.chancesofconversion}
                                    onChange={(e, newValue) => {
                                        this.setState({ chancesofconversion: newValue })
                                    }}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={10}
                                    min={0}
                                    max={100}
                                    marks={marks}
                                />
                            </Grid>
                            {this.state.followchecked ? <Grid item xs={12} sm={2}> </Grid> : ''}
                            {this.state.followchecked ?
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Reason for Follow Up Done"
                                        multiline
                                        rows={4}
                                        placeholder="Reason"
                                        variant="outlined"
                                        fullWidth
                                        name="reason"
                                        value={this.state.reason}
                                        onChange={this.onChange}
                                    />
                                </Grid> : ''}
                            <Grid item xs={12} sm={2}></Grid>
                            <Grid item xs={6} sm={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.copied}
                                            onChange={this.handleCheckbox}
                                            name="copied"
                                            color="primary"
                                        />
                                    }
                                    label="Copied"
                                />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.closed}
                                            onChange={this.handleCheckbox}
                                            name="closed"
                                            color="primary"
                                        />
                                    }
                                    label="Closed"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ marginTop: 15 }}>
                                <Tooltip title="Send WhatsApp">
                                    <IconButton onClick={() => {
                                        var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
                                        var timer = setInterval(function () {
                                            win.close()
                                            clearInterval(timer);
                                        }, 3000);
                                    }} style={{ backgroundColor: '#25D366', color: 'white', marginRight: 15 }} size="small">
                                        <WhatsApp fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Call">
                                    <IconButton color="primary" size="small" onClick={() => {
                                        var win1 = window.open("tel:" + this.state.mobile1)
                                        var timer1 = setInterval(function () {
                                            win1.close()
                                            clearInterval(timer1);
                                        }, 30000);
                                    }}>
                                        <Phone fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onSubmit} color="primary">add enquiry</Button>
                        <Button onClick={this.handleClose} color="primary"> Close</Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.FormControl}>
                    <Button variant="contained" color="secondary" onClick={() => {
                        this.setState({ opencircle: true })
                        axios.post($links.indiamartsync)
                            .then(res => {
                                if (res.data.errors === true) {
                                    this.setState({ response: res.data.message, opencircle: false, open: true, color: 'red' })
                                } else {
                                    this.setState({ response: res.data.message, open: true, opencircle: false })
                                    axios.post($links.indiamartenquiry, { cookies: localStorage.getItem('jwt') })
                                        .then(res => {
                                            if (res.data.errors === false) {
                                                this.setState({ enquiries: res.data.message })
                                            }
                                        })
                                }
                            })
                    }} >Syncronize IndiaMart Data</Button>
                </div>
                <div className={classes.Invoice}>
                    <MaterialTable style={{ borderRadius: 15, borderBottom: '3px solid #f0bc11', borderTop: '3px solid #4cc42d', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.4)' }}
                        title="All Enquiries"
                        columns={[
                            { title: 'QUERY ID', field: 'id', cellStyle: { width: '10%' }, headerStyle: nowrap, },
                            { title: 'SENDER NAME', field: 'SENDERNAME', headerStyle: nowrap, cellStyle: { maxWidth: 200, overflow: 'hidden', whiteSpace: 'nowrap' }, },
                            { title: 'SUBJECT', field: 'SUBJECT', cellStyle: { maxWidth: 300, overflow: 'hidden', whiteSpace: 'nowrap' }, headerStyle: nowrap },
                            { title: 'DATE', field: 'DATE_TIME_RE', cellStyle: nowrap, headerStyle: nowrap },
                            { title: 'MOBILE', field: 'MOB', headerStyle: nowrap, cellStyle: nowrap },
                            { title: 'COMPANY', field: 'GLUSR_USR_COMPANYNAME', headerStyle: nowrap, cellStyle: { maxWidth: 300, overflow: 'hidden', whiteSpace: 'nowrap' } },
                            { title: 'COPY', field: 'copy', hidden: true },
                            { title: 'CLOSED', field: 'closed', hidden: true }
                            , {
                                title: 'Action', field: 'cancelled', headerStyle: nowrap, render: rowData => (
                                    <div>

                                        <Button variant="contained" color="secondary" size="small"
                                            onClick={() => {
                                                this.setState({
                                                    id: rowData.id,
                                                    opendialog: true,
                                                    entity_name: rowData.GLUSR_USR_COMPANYNAME,
                                                    person_name: rowData.SENDERNAME,
                                                    mobile1: rowData.MOB.replace(/\D/g, '').slice(-10),
                                                    mobile2: rowData.MOBILE_ALT,
                                                    email: rowData.SENDEREMAIL,
                                                    address: rowData.ENQ_ADDRESS,
                                                    state: rowData.ENQ_STATE ? rowData.ENQ_STATE.toUpperCase() : '',
                                                    district: rowData.ENQ_CITY,
                                                    beginning_date: new Date(rowData.DATE_TIME_RE),
                                                    product_id: rowData.product_id,
                                                    whatappschecked: false,
                                                    phonechecked: false,
                                                    followchecked: false,
                                                    remark: rowData.SUBJECT + '\n' + (rowData.ENQ_MESSAGE ? rowData.ENQ_MESSAGE.replaceAll("<br>", "\n") : ''),
                                                })
                                            }}
                                            disabled={rowData.copy ? true : false}
                                        >OPEN</Button>
                                    </div>
                                )
                            },

                        ]}
                        data={enquiries.map(alldata => (
                            {
                                QUERY_ID: alldata.QUERY_ID,
                                id: alldata.id,
                                SENDERNAME: alldata.SENDERNAME,
                                SUBJECT: alldata.SUBJECT,
                                DATE_TIME_RE: alldata.DATE_TIME_RE,
                                GLUSR_USR_COMPANYNAME: alldata.GLUSR_USR_COMPANYNAME,
                                MOB: alldata.MOB,
                                copy: alldata.copy,
                                closed: alldata.closed,
                                QTYPE: alldata.QTYPE,
                                SENDEREMAIL: alldata.SENDEREMAIL,
                                READ_STATUS: alldata.READ_STATUS,
                                SENDER_GLUSR_USR_ID: alldata.SENDER_GLUSR_USR_ID,
                                QUERY_MODID: alldata.QUERY_MODID,
                                QUERY_MODREFID: alldata.QUERY_MODREFID,
                                DIR_QUERY_MODREF_TYPE: alldata.DIR_QUERY_MODREF_TYPE,
                                ORG_SENDER_GLUSR_ID: alldata.ORG_SENDER_GLUSR_ID,
                                ENQ_MESSAGE: alldata.ENQ_MESSAGE,
                                ENQ_ADDRESS: alldata.ENQ_ADDRESS,
                                ENQ_CALL_DURATION: alldata.ENQ_CALL_DURATION,
                                ENQ_RECEIVER_MOB: alldata.ENQ_RECEIVER_MOB,
                                ENQ_CITY: alldata.ENQ_CITY,
                                ENQ_STATE: alldata.ENQ_STATE,
                                PRODUCT_NAME: alldata.PRODUCT_NAME,
                                COUNTRY_ISO: alldata.COUNTRY_ISO,
                                EMAIL_ALT: alldata.EMAIL_ALT,
                                MOBILE_ALT: alldata.MOBILE_ALT,
                                PHONE: alldata.PHONE,
                                PHONE_ALT: alldata.PHONE_ALT,
                                IM_MEMBER_SINCE: alldata.IM_MEMBER_SINCE,
                            }))}
                        options={{
                            padding: 'dense',
                            pageSize: 20,
                            actionsColumnIndex: -1,
                            draggable: false,
                            rowStyle: rowData => {

                                if (rowData.id === this.state.id) {
                                    return { backgroundColor: '#ebebeb' };
                                }
                            },
                        }}
                    />
                </div>
                <Backdrop className={classes.backdrop} open={this.state.opencircle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >

        );
    }
}

export default withStyles(useStyles)(IndiaMartEnquiry)