import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React from 'react';
import './App.css';
import Login from './components/login';
import Invoice from './components/invoices'
import Customer from './components/customer';
import Addcustomer from './components/addcustomer';
import Addinvoice from './components/addinvoice';
import Addproduct from './components/addproduct';
import generateinvoice from './components/generateinvoice';
import Products from './components/products';
import Profile from './components/profile';
import Editcustomer from './components/editcustomer';
import Shipping from './components/shipping';
import PrimarySearchAppBar from './components/layout/navbaradmin'
import { Fragment } from 'react';
import addenquiry from './components/addenquiry';
import EnquiryDashboard from './components/EnquiryDashboard';
import addressinvoice from './components/addressinvoice';
import landscapeaddressinvoice from './components/landscapeaddressinvoice';
import indiamartenquirylog from './components/indiamartenquirylog';
import accountmanagement from './components/accountmanagement';
import adminusers from './components/adminusers';
import allenquiries from './components/allenquiries';

function App() {
  return (
    <div className="App">
      <Router>

        <Switch>
          <Route exact path="/generateinvoice" component={generateinvoice} />
          <Route exact path="/addressinvoice" component={addressinvoice} />
          <Route exact path="/landscapeaddress" component={landscapeaddressinvoice} />
          <Route exact path="/" component={Login} />
          <Fragment>
            <PrimarySearchAppBar />
            <Route exact path="/invoice" component={Invoice}></Route>
            <Route exact path="/customer" component={Customer}></Route>
            <Route exact path="/addcustomer" component={Addcustomer}></Route>
            <Route exact path="/addinvoice" component={Addinvoice}></Route>
            <Route exact path="/addproduct" component={Addproduct}></Route>
            <Route exact path="/products" component={Products}></Route>
            <Route exact path="/profile" component={Profile}></Route>
            <Route exact path="/editcustomer" component={Editcustomer}></Route>
            <Route exact path="/editshipping" component={Shipping}></Route>
            <Route exact path="/addenquiry" component={addenquiry}></Route>
            <Route exact path="/enquiry/dashboard" component={EnquiryDashboard}></Route>
            <Route exact path="/enquiry/indiamart" component={indiamartenquirylog}></Route>
            <Route exact path="/accountmanagement" component={accountmanagement}></Route>
            <Route exact path="/admin/users" component={adminusers}></Route>
            <Route exact path="/enquiry/allenquires" component={allenquiries}></Route>
          </Fragment>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
