import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, Container, Grid, Paper, TextField, Typography, Tooltip,
  Select, FormControl, InputLabel, Snackbar, FormControlLabel, Checkbox, InputAdornment, Slider,
} from '@material-ui/core'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import $links from './variable'
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
const useStyles = (theme) => ({
  button: {
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right', marginTop: 20,
    }
  },
  dates: {
    margin: theme.spacing(2, 1, 3),

  },
  addorder: {
    position: 'absolute',
    width: 150
  },
  adduser: {
    margin: theme.spacing(0, 20, 0),
    width: 150
  }
  /*  <Grid item xs={12} sm={4} >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.add_contact}
                                onChange={this.handleCheckbox}
                                name="add_contact"
                                color="primary"
                              />
                            }
                            label="Add Contact"
                          />
                        </Grid>*/
});
const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];
const today = new Date()
class AddEnquiry extends Component {

  constructor() {
    super()
    this.state = {
      entity_name: '',
      person_name: '',
      mobile1: '',
      mobile2: '',
      gstno: '',
      email: '',
      address: '',
      postal_code: '',
      state: 0,
      district: '',
      city: '',
      create_at: '',
      errors: '',
      customer_type: '',
      grade: 'A',
      deals_with: [],
      open: false,
      whatsapp1: true,
      whatsapp2: false,
      chancesofconversion: 50,
      unit: 1,
      products: [],
      product_id: 1,
      lead_source: '',
      beginning_date: today,
      last_date: today,
      follow_date: today,
      remark: '',
      reason: '',
      whatappschecked: false,
      phonechecked: false,
      followchecked: false,
      res_customertype: [],
      res_customerdealswith: [],
      res_leadsource: [],
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    axios.post($links.enquiry, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({ products: res.data.message, res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith, res_leadsource: res.data.leadsource, customer_type: res.data.type, lead_source: res.data.lead_source })
        }
        else {
          alert(res.data.message)
        }
      })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleClose(e, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })

  }
  handleCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
    if (e.target.name === 'whatappschecked' && e.target.checked && this.state.mobile1) {
      var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
      var timer = setInterval(function () {
        win.close()
        clearInterval(timer);
      }, 3000);
    }
    else if (e.target.name === 'phonechecked' && e.target.checked && this.state.mobile1) {
      window.open("tel:" + this.state.mobile1);
    }
  };
  onSubmit = async (e) => {
    e.preventDefault()
    const enquiry_details = {
      entity_name: this.state.entity_name,
      person_name: this.state.person_name,
      mobile1: this.state.mobile1,
      mobile2: this.state.mobile2,
      gstno: this.state.gstno,
      email: this.state.email,
      address: this.state.address,
      postal_code: this.state.postal_code,
      state: this.state.state,
      district: this.state.district,
      city: this.state.city,
      customer_type: this.state.customer_type,
      grade: this.state.grade,
      deals_with: this.state.deals_with,
      whatsapp1: this.state.whatsapp1,
      whatsapp2: this.state.whatsapp2,
      chancesofconversion: this.state.chancesofconversion,
      unit: this.state.unit,
      product_id: this.state.product_id,
      lead_source: this.state.lead_source,
      beginning_date: this.state.beginning_date,
      last_date: this.state.last_date,
      follow_date: this.state.follow_date,
      remark: this.state.remark,
      reason: this.state.reason,
      checked: [this.state.whatappschecked,
      this.state.phonechecked,
      this.state.followchecked],
      create_at: new Date().toLocaleString(),
      cookies: localStorage.getItem('jwt')
    }
    await axios.post($links.addenquiry, enquiry_details)
      .then(res => {
        if (res.data.errors === false) {
          alert(res.data.message)
          this.props.history.push('/enquiry/dashboard')
        } else {
          this.setState({ errors: res.data.message, open: true })
        }
      })

  }
  render() {
    var { products = [] } = this.state
    const { classes } = this.props
    const { res_customertype = [] } = this.state
    const { res_customerdealswith = [] } = this.state
    const { res_leadsource = [] } = this.state
    return (
      <div>

        <Paper style={{ margin: 5 }}>
          <Container style={{ marginTop: 35 }}>
            <Typography variant="h4" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Add Enquiry</Typography>
            <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity="error">
                {this.state.errors}
              </Alert>
            </Snackbar>
            <form onSubmit={this.onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="outline-flexible"
                    label="Entity Name"
                    placeholder="Enter Entity Name"
                    variant="outlined"
                    fullWidth
                    name="entity_name"
                    value={this.state.entity_name}
                    autoFocus
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Person Name"
                    placeholder="Enter Name"
                    variant="outlined"
                    fullWidth
                    name="person_name"
                    value={this.state.person_name}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Mobile Number 1"
                    placeholder="Mobile Number 1"
                    fullWidth
                    name="mobile1"
                    value={this.state.mobile1}
                    onChange={this.onChange}
                    id="outlined-start-adornment"
                    onInput={(e) => {

                      if (e.target.value.length === 10) {
                        const postal = {
                          mobile: e.target.value
                        }
                        axios.post($links.existingmobile, postal)
                          .then(res => {
                            if (res.data.errors) {
                              this.setState({ errors: res.data.message, open: true })
                            }
                          })
                          .catch(error =>{
                            this.setState({ errors: error.message, open: true })
                          })
                      }
                    }}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start" >
                          <Tooltip title="Set this as Whatsapp Number">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="whatsapp1"
                                  color="primary"
                                  checked={this.state.whatsapp1}
                                  onChange={this.handleCheckbox}
                                />
                              }
                            />
                          </Tooltip>
                        </InputAdornment>,
                      type: "Number"
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Mobile Number 2"
                    placeholder="(Optional)"
                    fullWidth
                    name="mobile2"
                    value={this.state.mobile2}
                    onChange={this.onChange}
                    id="outlined-start-adornment1"
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start" >
                          <Tooltip title="Set this as Whatsapp Number">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="whatsapp2"
                                  color="primary"
                                  checked={this.state.whatsapp2}
                                  onChange={this.handleCheckbox}
                                />
                              }
                            />
                          </Tooltip>
                        </InputAdornment>,
                      type: "Number"
                    }}
                    onInput={(e) => {

                      if (e.target.value.length === 10) {
                        const postal = {
                          mobile: e.target.value
                        }
                        axios.post($links.existingmobile, postal)
                          .then(res => {
                            if (res.data.errors) {
                              this.setState({ errors: res.data.message, open: true })
                            }
                          })
                          .catch(error =>{
                            this.setState({ errors: error.message, open: true })
                          })
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="GSTN"
                    placeholder="GST Number"
                    variant="outlined"
                    fullWidth
                    name="gstno"
                    value={this.state.gstno}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    placeholder="Enter Address"
                    variant="outlined"
                    fullWidth
                    name="address"
                    value={this.state.address}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Postal Code"
                    placeholder="6 digit number"
                    variant="outlined"
                    fullWidth
                    name="postal_code"
                    value={this.state.postal_code}
                    onChange={this.onChange}
                    inputProps={{ maxLength: 6 }}
                    onInput={(e) => {

                      if (e.target.value.length === 6) {
                        const postal = {
                          postal_code: e.target.value
                        }
                        axios.post($links.postal_code, postal)
                          .then(res => {
                            if (res.data.errors === true) {
                              this.setState({ errors: res.data.message, open: true })
                            } else {
                              this.setState({ state: res.data.state, district: res.data.district, city: res.data.city })
                            }
                          })
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={this.state.state}
                      onChange={this.onChange}
                      label="State"
                      name="state"
                    >
                      <option value="0" disabled>SELECT STATE</option>
                      <option value="ANDHRA PRADESH">Andhra Pradesh</option>
                      <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                      <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                      <option value="ASSAM">Assam</option>
                      <option value="BIHAR">Bihar</option>
                      <option value="CHANDIGARH">Chandigarh</option>
                      <option value="CHATTISGARH">Chhattisgarh</option>
                      <option value="DADRA & NAGAR HAVELI">Dadra and Nagar Haveli</option>
                      <option value="DAMAN AND DIU">Daman and Diu</option>
                      <option value="DELHI">Delhi</option>
                      <option value="LAKSHADWEEP">Lakshadweep</option>
                      <option value="PUDUCHERRY">Puducherry</option>
                      <option value="GOA">Goa</option>
                      <option value="GUJARAT">Gujarat</option>
                      <option value="HARYANA">Haryana</option>
                      <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                      <option value="JAMMU AND KASHMIR">Jammu and Kashmir</option>
                      <option value="JHARKHAND">Jharkhand</option>
                      <option value="KARNATAKA">Karnataka</option>
                      <option value="KERALA">Kerala</option>
                      <option value="MADHYA PRADESH">Madhya Pradesh</option>
                      <option value="MAHARASHTRA">Maharashtra</option>
                      <option value="MANIPUR">Manipur</option>
                      <option value="MEGHALAYA">Meghalaya</option>
                      <option value="MIZORAM">Mizoram</option>
                      <option value="NAGALAND">Nagaland</option>
                      <option value="ODISHA">Odisha</option>
                      <option value="PUNJAB">Punjab</option>
                      <option value="RAJASTHAN">Rajasthan</option>
                      <option value="SIKKIM">Sikkim</option>
                      <option value="TAMIL NADU">Tamil Nadu</option>
                      <option value="TELANGANA">Telangana</option>
                      <option value="TRIPURA">Tripura</option>
                      <option value="UTTAR PRADESH">Uttar Pradesh</option>
                      <option value="UTTARAKHAND">Uttarakhand</option>
                      <option value="WEST BENGAL">West Bengal</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="City"
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    name="city"
                    value={this.state.city}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="District"
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    name="district"
                    value={this.state.district}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Customer Type</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={this.state.customer_type}
                      onChange={this.onChange}
                      label="Select Custom"
                      name="customer_type"
                    >
                      <option disabled>Select Customer Type</option>
                      {res_customertype.map(res_customertype => (
                        <option key={res_customertype} value={res_customertype}>{res_customertype}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Select Grade</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={this.state.grade}
                      onChange={this.onChange}
                      label="Select Custo"
                      name="grade"
                    >
                      <option value='A'>A</option>
                      <option value='B'>B</option>
                      <option value='C'>C</option>
                      <option value='D'>D</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Units"
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    name="unit"
                    InputProps={{
                      type: "Number"
                    }}
                    value={this.state.unit}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={products}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        this.setState({ product_id: newValue.product_id });
                      }
                    }}
                    getOptionLabel={(option) => "Product Name: " + option.name_of_good + " | Model: " + option.model}
                    name="name"
                    renderInput={(params) => <TextField {...params} label="Search Product" variant="outlined" fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={res_customerdealswith}
                    fullWidth
                    value={this.state.deals_with}
                    getOptionLabel={(option) => option}
                    onChange={(e, newValue) => {
                      this.setState({ deals_with: newValue })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Customer Deals With" fullWidth placeholder="Select Dealer deals with" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Lead Source</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={this.state.lead_source}
                      onChange={this.onChange}
                      label="Lead Source"
                      name="lead_source"
                    >
                      <option disabled>Select Lead Source</option>
                      {res_leadsource.map(res_leadsource => (
                        <option key={res_leadsource} value={res_leadsource}>{res_leadsource}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={12} sm={3}>
                    <KeyboardDatePicker
                      fullWidth
                      format="dd/MM/yyyy"
                      margin="dense"
                      id="date-picker-inline1"
                      name="beginning_date"
                      label="Beginning"
                      value={this.state.beginning_date}
                      onChange={(date) => {
                        this.setState({ beginning_date: date });
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          fullWidth
                          format="dd/MM/yyyy"
                          margin="dense"
                          id="date-picker-inline3"
                          name="last_date"
                          label="Last Contact"
                          value={this.state.last_date}
                          onChange={(date) => {
                            this.setState({ last_date: date });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          fullWidth
                          format="dd/MM/yyyy"
                          margin="dense"
                          id="date-picker-inline2"
                          name="follow_date"
                          label="Follow Up"
                          value={this.state.follow_date}
                          onChange={(date) => {
                            this.setState({ follow_date: date });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.whatappschecked}
                              onChange={this.handleCheckbox}
                              name="whatappschecked"
                              color="primary"
                            />
                          }
                          label="WhatsApp Send"
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.phonechecked}
                              onChange={this.handleCheckbox}
                              name="phonechecked"
                              color="primary"
                            />
                          }
                          label="Phone Call Done"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.followchecked}
                              onChange={this.handleCheckbox}
                              name="followchecked"
                              color="primary"
                            />
                          }
                          label="Follow Up Done"
                        />
                      </Grid>
                    </Grid></Grid>
                </MuiPickersUtilsProvider>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Remarks On Enquiry"
                    multiline
                    rows={4}
                    placeholder="Remarks"
                    variant="outlined"
                    fullWidth
                    name="remark"
                    value={this.state.remark}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography id="discrete-slider" align="left" gutterBottom><b>Chances Of Conversion :</b></Typography>
                  <Slider
                    value={this.state.chancesofconversion}
                    onChange={(e, newValue) => {
                      this.setState({ chancesofconversion: newValue })
                    }}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={10}
                    min={0}
                    max={100}
                    marks={marks}
                  />
                </Grid>
                {this.state.followchecked ? <Grid item xs={12} sm={2}></Grid> : ''}
                {this.state.followchecked ?
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Reason for Follow Up Done"
                      multiline
                      rows={4}
                      placeholder="Reason"
                      variant="outlined"
                      fullWidth
                      name="reason"
                      value={this.state.reason}
                      onChange={this.onChange}
                    />
                  </Grid> : ''}
                <Grid item xs={12} sm={6} className={classes.button}>
                  <Button variant="contained" type="submit" color="primary" size="large">Add Enquiry</Button>
                </Grid>
              </Grid>
            </form>
          </Container>

        </Paper>

      </div>

    );
  }
}
export default withStyles(useStyles)(AddEnquiry)

