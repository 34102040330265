import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Container, Grid, Typography, Divider, Table,
    TableContainer, TableBody, TableCell, TableHead, TableRow, List, ListItem, Button,
} from '@material-ui/core';
import axios from 'axios';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';
import $links from './variable';
const useStyles = (theme) => ({
    tablewidth: {
        [theme.breakpoints.up('sm')]: {

        },
    },
    dates: {
        margin: theme.spacing(2, 1, 3),

    },
    addorder: {
        position: 'absolute',
        width: 150
    },
    adduser: {
        margin: theme.spacing(0, 20, 0),
        width: 150
    },
    Divider: {
        marginTop: 5,

    },
    tableheading: {
        fontWeight: 'bold',
        fontSize: 18,
        border: "1px solid black",
        whiteSpace: "nowrap"
    },
    tableheading0: {
        fontWeight: 'bold',
        fontSize: 18,
        whiteSpace: "nowrap"
    },
    tablecell0: { fontSize: 16 },
    tablecell: { fontSize: 16, border: "1px solid black" },
    tablecell1: { fontSize: 17, border: "1px solid black" },
    root: {
        borderWidth: 1,
        borderColor: "black",
    },
    root1: {
        borderWidth: 1,
        borderColor: "black",
        [theme.breakpoints.up('sm')]: {
            borderWidth: 1,
            borderColor: "black",
            width: "96%"
        },

    },
    cardheader: {
        textAlign: "left",
        padding: theme.spacing(1.5),
        fontSize: 18,
        backgroundColor: "#faf7f7",
        borderBottom: "1px solid black"
    },
    cardcontent: {
        fontSize: 18,
        marginLeft: 10,
        marginTop: 10,
        lineHeight: 1.6
    },
    LanguageIcon: {
        overflowWrap: "break-word", marginTop: 2, marginRight: 5, paddingLeft: 30,
    }
});

class GenerateInvoice extends Component {

    constructor() {
        super()
        this.state = {
            invoice_details: [],
            customer_details: [],
            billto: '',
            product_details: [],
            subtotal: 0,
            gst: 0,
            inwords: '',
            invoice_type: '',
            shipping: []
        }
        const invoice = {
            invoice_number: localStorage.getItem('generateinvoiceid'),
            cookies: localStorage.getItem('jwt')
        }
        axios.post($links.findinvoice, invoice)
            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    const invoice_type = res.data.message1.invoice_type
                    this.setState({ invoice_details: res.data.message1, billto: res.data.message1.billto, product_details: res.data.message1.product_details, inwords: res.data.inwords, shipping: res.data.shipping })
                    document.title = "INVOICE - " + res.data.message1.invoice_number;
                    var i = 0
                    var subtotal = 0
                    var gst = 0
                    var product_details = this.state.product_details
                    while (i < product_details.length) {
                        subtotal = subtotal + product_details[i].qty * product_details[i].unit_price
                        gst = gst + (product_details[i].qty * (product_details[i].unit_price * product_details[i].gst / 100))
                        i++
                    }
                    if (invoice_type === 1) {
                        this.setState({ invoice_type: "PERFORMA" })
                        document.title = "PERFORMA INVOICE - " + res.data.message1.invoice_number;
                    } else if (invoice_type === 2) {
                        document.title = "QUOTATION INVOICE - " + res.data.message1.invoice_number;
                        this.setState({ invoice_type: "QUOTATION" })
                    }
                    this.setState({ subtotal: subtotal, gst: Math.round(gst * 1e2) / 1e2 })
                }
                else {
                    alert(res.data.message)
                }
            })

    }
    render() {
        const { classes } = this.props
        const { product_details = [] } = this.state
        const { shipping = [] } = this.state
        return (
            <div id="invoiceborder">
                <Container>
                    <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => window.top.close()}>Close</Button>
                    <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => {
                        const win = window.open("/addressinvoice", "_blank");
                        win.focus();
                    }}>Print Address</Button>
                    <Button id="printPageButton" variant="outlined" style={{ margin: 20 }} onClick={() => window.print()}>Print</Button>
                    <Grid container spacing={3} style={{ paddingTop: 50 }}>
                        <Grid item lg={6} xs={6}>
                            <img src="full_big_tag_line_black.svg" style={{ display: "flex" }} alt="logo" width="350"></img>
                            <Typography variant="h5" style={{ marginTop: 30, fontSize: 29 }} align="left">Telexa Technology Pvt. Ltd.</Typography>
                            <Typography variant="body2" align="left" style={{ marginTop: 25, fontSize: 17.5, whiteSpace: 'nowrap' }}>34/II-C New Palasia, Indore, Madhya Pradesh, India 452014<br></br> CIN: U31900MP2021PTC056222 | PAN: AAICT6532D <br></br><b> GSTIN: 23AAICT6532D1ZK</b></Typography>
                            <Typography variant="subtitle1" align="left" style={{ marginTop: 25, fontSize: 18, display: "flex" }}>
                                <PhoneIcon style={{ marginTop: 5, marginRight: 5 }} /> +91 8989054333, 08962307685</Typography>
                            <Typography variant="subtitle1" align="left" style={{ marginTop: 5, fontSize: 16, display: "flex" }} display="inline">
                                <MailOutlineIcon style={{ marginTop: 2, marginRight: 5 }} /> sales@telexa.in <LanguageIcon className={classes.LanguageIcon} /> www.telexa.in</Typography>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Typography variant="h5" align="right">{this.state.invoice_type} INVOICE - {this.state.invoice_details.invoice_number}</Typography>
                            <Divider className={classes.Divider} />
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableheading0}>Invoice Number</TableCell>
                                            <TableCell className={classes.tableheading0}>Date</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.invoice_number}</TableCell>
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.invoice_date}</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tableheading0}>PO Number/Your Ref</TableCell>
                                            <TableCell className={classes.tableheading0}>PO Date</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.po_number}</TableCell>
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.po_date}</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tableheading0}>DC Number</TableCell>
                                            <TableCell className={classes.tableheading0}>DC Date</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.dc_number}</TableCell>
                                            <TableCell className={classes.tablecell0}>{this.state.invoice_details.dc_date}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item lg={6} xs={6}>

                            <Typography align="left" variant="h6" style={{ fontWeight: "bold" }}>Company Bank Details</Typography>
                            <Typography align="left" >Bank Name :	IndusInd Bank</Typography>
                            <Typography align="left">Entity Name :	TELEXA TECHNOLOGY PRIVATE LIMITED</Typography>
                            <Typography align="left">Account No :	258989954333</Typography>
                            <Typography align="left">IFSC Code :	INDB0000850</Typography>
                            <Typography align="left">Branch :	Yashwant Niwas Road , Indore</Typography>

                        </Grid>
                        <Grid item lg={6} xs={6} >
                            <Typography align="left" variant="h6" style={{ marginLeft: 10, marginTop: -70, fontWeight: 'bold' }}>Ship To:</Typography>
                            <Typography variant="body2" align="left" className={classes.cardcontent}>
                                <b>{this.state.billto[7]}</b> {this.state.billto[7] ? '-' : ''} {this.state.billto[0]}<br />
                                            Address: {this.state.billto[1]}<br />
                                            State : {this.state.billto[2]}<br />
                                            Pincode : {this.state.billto[3]}<br />
                                            Phone : {this.state.billto[4]}<br />
                                            Email : {this.state.billto[5]}<br />
                                            GSTIN  : {this.state.billto[6]}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} style={{ paddingRight: 24 }}>
                            <Table style={{ border: "1px solid black", }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableheading}>S.No.</TableCell>
                                        <TableCell className={classes.tableheading}>Description of Good</TableCell>
                                        <TableCell className={classes.tableheading} align="right">Qty</TableCell>
                                        <TableCell className={classes.tableheading} align="right">Unit Price</TableCell>
                                        <TableCell className={classes.tableheading} align="right">Sub Total</TableCell>
                                        <TableCell className={classes.tableheading} align="right">HSN</TableCell>
                                        <TableCell className={classes.tableheading} align="right">GST (₹)</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {product_details.map((product_details, index) => (
                                        <TableRow component="tr" key={product_details.product_id} >
                                            <TableCell className={classes.tablecell1}>{index + 1}</TableCell>
                                            <TableCell className={classes.tablecell1} style={{ minWidth: 300, maxWidth: 310, wordWrap: 'break-word' }} >{product_details.name_of_good + ' - ' + product_details.model}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">{product_details.qty}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {product_details.unit_price}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {product_details.unit_price * product_details.qty}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">{product_details.hsn_code}</TableCell>
                                            <TableCell className={classes.tablecell1} style={{ whiteSpace: 'nowrap' }} align="right">₹ {Math.round(product_details.qty * (product_details.unit_price * product_details.gst / 100)) + ' (' + product_details.gst + '%)'}</TableCell>
                                        </TableRow>
                                    ))}
                                    {shipping ?
                                        <TableRow component="tr" key={product_details.product_id} >
                                            <TableCell className={classes.tablecell1}></TableCell>
                                            <TableCell className={classes.tablecell1}>Shipping - {shipping.shipping_mode}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">1</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {shipping.shipping_charge}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {shipping.shipping_charge}</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">9968</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {Math.round(shipping.shipping_charge * shipping.shipping_gst / 100) + ' (' + shipping.shipping_gst + '%)'}</TableCell>
                                        </TableRow>
                                        : null}
                                </TableBody>
                            </Table>

                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <Typography align="left" variant="subtitle1" style={{ fontSize: 16 }}>Total in words:</Typography>
                            <Typography align="left" style={{ fontWeight: "bold", fontSize: 16, marginBottom: 10 }}>Rupees {this.state.inwords} Only</Typography>
                            <Divider />
                            <div >
                                <List>
                                    <ListItem>
                                        <FiberManualRecordIcon style={{ fontSize: 12 }} />
                                        <Typography style={{ fontSize: 16, paddingLeft: 10, }}>Goods once sold will not be taken back or exchanged.</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <FiberManualRecordIcon style={{ fontSize: 12 }} />
                                        <Typography style={{ fontSize: 16, paddingLeft: 10 }}>Our responsibility ceases once the goods have left our premises.</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <FiberManualRecordIcon style={{ fontSize: 12 }} />
                                        <Typography style={{ fontSize: 16, paddingLeft: 10, }}>All disputes are subject to Indore jurisdiction.</Typography>
                                    </ListItem>
                                </List>
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={6}>
                            <TableContainer>
                                <Table style={{ border: "1px solid black" }} size="small">

                                    <TableBody>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell1}>Shipping INR</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {shipping ? Math.round(shipping.shipping_charge) : 0}</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell1}>Sub-total INR</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {this.state.subtotal}</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell1}>GST INR</TableCell>
                                            <TableCell className={classes.tablecell1} align="right">₹ {Math.round(this.state.gst) + Math.round(shipping ? shipping.shipping_charge * shipping.shipping_gst / 100 : 0)}</TableCell>
                                        </TableRow>
                                        <TableRow component="tr">
                                            <TableCell className={classes.tablecell1} style={{ fontWeight: 'bold' }}>Total Amount INR</TableCell>
                                            <TableCell className={classes.tablecell1} align="right" style={{ fontWeight: 'bold' }}>₹ {Math.round(this.state.invoice_details.grand_total)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography align="right" style={{ fontSize: 17.5, color: "grey", paddingTop: 20 }}>For Telexa Technology Pvt. Ltd.</Typography>
                        </Grid>
                        <br /><br /><br /><br /><br /><br /><br /><br />
                        <Grid item lg={12} xs={12}>
                            <Typography align="right" style={{ fontSize: 17.5, paddingTop: 100 }}>Authorised Signatory</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div >

        );
    }
}

export default withStyles(useStyles)(GenerateInvoice)