import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Hidden, Typography, FormControl, InputLabel, Select } from '@material-ui/core';
import MaterialTable from 'material-table'
import axios from 'axios'
import $links from './variable'
import { Autocomplete } from '@material-ui/lab';
var tags = [
  'customer',
  'enquiry',
  'invoice',
  'product',
  'accounts'
]
const useStyles = (theme) => ({
  root: {
    marginTop: 45,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 60, marginRight: 5,
      marginTop: 45
    },
  },
  order: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 150,
  },
});
class UserDisplay extends Component {

  constructor() {
    super()
    this.state = {
      users: [],
      fullname: '',
      email: '',
      password: '',
      admin: 0,
      permission: [],
      id: '',
      open: false,
      errors: '',
      dialog: 'add user',
      Hidden1: ['sm', 'lg', 'md', 'xl', 'xs'],
      Hidden2: ['sm', 'lg', 'md', 'xl', 'xs'],
    }
    this.handleClose = this.handleClose.bind(this)
    this.onChange = this.onChange.bind(this)
    const cookie = { cookies: localStorage.getItem('jwt') }
    axios.post($links.displayuser, cookie)
      .then(res => {
        if (res.data.auth) {
          window.location.replace('/')
        }
        else if (res.data.errors) {
          alert(res.data.message)
        } else {
          this.setState({ users: res.data.message })
        }
      })
  }
  handleClose = () => {
    this.setState({ open: false, texterror: "" })
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    const { classes } = this.props
    var { users = [] } = this.state
    return (
      <div className={classes.root}>
        <Dialog open={this.state.open} onClose={this.handleClose} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
          <Hidden only={this.state.Hidden1}>
            <DialogTitle id="form-dialog-title">Add User</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    name="fullname"
                    autoFocus
                    autoComplete="fullname"
                    value={this.state.fullname}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={tags}
                    value={this.state.permission}
                    getOptionLabel={(option) => option}
                    onChange={(e, newValue) => {
                      this.setState({ permission: newValue })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Permissions" fullWidth placeholder="Select Pages Permissions to allow" />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={async () => {
                const userData = {
                  fullname: this.state.fullname,
                  email: this.state.email,
                  password: this.state.password,
                  permission: this.state.permission,
                  cookies: localStorage.getItem('jwt')
                }
                await axios.post($links.adduser, userData)
                  .then(res => {
                    if (res.data.errors === false) {
                      alert(res.data.message)
                      window.location.reload()
                    } else {
                      alert(res.data.message)
                    }
                  })
              }
              } color="primary">
                Add USer
          </Button>
              <Button onClick={this.handleClose} color="primary">
                Cancel
          </Button>
            </DialogActions>
          </Hidden>
          <Hidden only={this.state.Hidden2}>
            <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="fullname"
                    label="Full Name"
                    name="fullname"
                    autoFocus
                    autoComplete="fullname"
                    value={this.state.fullname}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    options={tags}
                    value={this.state.permission}
                    getOptionLabel={(option) => option}
                    onChange={(e, newValue) => {
                      this.setState({ permission: newValue })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Permissions" fullWidth placeholder="Select Pages Permissions to allow" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-age-native-simple" >User Type</InputLabel>
                    <Select
                      fullWidth
                      native
                      value={this.state.admin}
                      onChange={this.onChange}
                      label="User Type"
                      name="admin"
                    >
                      <option value={0}>User</option>
                      <option value={1}>Admin</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={async () => {
                const userData = {
                  id: this.state.id,
                  fullname: this.state.fullname,
                  email: this.state.email,
                  admin: this.state.admin,
                  password: this.state.password,
                  permission: this.state.permission,
                  cookies: localStorage.getItem('jwt')
                }
                await axios.post($links.updateuser, userData)
                  .then(res => {
                    if (res.data.errors === false) {
                      alert(res.data.message)
                      window.location.reload()
                    } else {
                      alert(res.data.message)
                    }
                  })
              }
              } color="primary">
                update USer
          </Button>
              <Button onClick={this.handleClose} color="primary">
                Cancel
          </Button>
            </DialogActions>
          </Hidden>
        </Dialog>
        <Grid container justify='flex-end'>
          <Button onClick={() => this.setState({ open: true, Hidden1: [], Hidden2: ['sm', 'lg', 'md', 'xl', 'xs'], })}
            variant="contained"
            color="primary" className={classes.order}
          >add User</Button>
        </Grid>
        <MaterialTable
          title="Users"
          columns={[
            { title: 'ID', field: 'id', cellStyle: { width: '1%' }, headerStyle: nowrap },
            { title: 'Full Name', field: 'fullname', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 100, overflow: 'hidden' } },
            { title: 'email', field: 'email', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 100, overflow: 'hidden' } },
            { title: 'Password', field: 'simple', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 100, overflow: 'hidden' } },
            {
              title: 'Permissions', field: 'permission', headerStyle: nowrap, cellStyle: { whiteSpace: 'nowrap', maxWidth: 500, overflow: 'hidden' }, render: rowData => (
                <div>
                  {rowData.permission.map((permission, index) => (
                    <Typography display="inline" variant="body1" style={{ fontSize: 15 }} key={permission}>{permission}{index + 1 !== rowData.permission.length ? ', ' : ''}</Typography>

                  ))}
                </div>
              )
            },
            { title: 'Created', field: 'created', editable: 'never', searchable: false, headerStyle: nowrap, cellStyle: nowrap },
            { title: 'Admin', field: 'admin', editable: 'onUpdate', cellStyle: { width: '1%' }, lookup: { 1: 'Yes', 0: 'No' }, headerStyle: nowrap, searchable: false },
          ]}
          data={users.map(users => ({
            id: users.id, fullname: users.fullname, email: users.email, simple: users.simple,
            permission: users.permission, admin: users.admin, created: users.created,
          }))}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit User',
              onClick: (event, rowData) => {
                this.setState({ id: rowData.id, open: true, admin: rowData.admin, Hidden1: ['sm', 'lg', 'md', 'xl', 'xs'], fullname: rowData.fullname, Hidden2: [], email: rowData.email, permission: rowData.permission, password: rowData.simple })
              }
            }
          ]}
          editable={{
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...users];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  this.setState({ users: [...dataDelete] });
                  const userData = {
                    id: oldData.id,
                    cookies: localStorage.getItem('jwt')
                  }
                  axios.post($links.deleteuser, userData)
                    .then(res => {
                      if (res.data.errors === false) {
                        alert(res.data.message)
                      } else {
                        this.setState({ errors: res.data.message })
                        alert(this.state.error)
                      }
                    })
                  resolve()
                }, 500)
              }),

          }}
          options={{
            sorting: true,
            actionsColumnIndex: -1,
            padding: 'dense',
            exportButton: true,
          }
          }
        />
      </div>
    );
  }
}
export default withStyles(useStyles)(UserDisplay)
