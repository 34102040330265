import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import MaterialTable from 'material-table'
import {
  Grid, Container,
  Card, CardContent, Typography, Avatar, Button, DialogActions, Checkbox, FormControlLabel, Slider, TextField, Dialog, DialogContent, InputAdornment, Tooltip, FormControl, InputLabel, Select, Snackbar,
} from '@material-ui/core';
import axios from 'axios';
import $links from './variable'
import { PeopleAlt, LocalShipping, CameraAlt } from '@material-ui/icons'
import { Alert, Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
var dates = [
  new Date(),
  new Date(),
  new Date(),
  new Date(),
  new Date(),
  new Date(),
  new Date(),
]
var i = 0
while (i <= 6) {
  dates[i].setDate(dates[i].getDate() - i)
  var dd = dates[i].getDate();
  var mm = dates[i].getMonth() + 1
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  var yyyy = dates[i].getFullYear();
  dates[i] = dd + '/' + mm + '/' + yyyy
  i++;
}
const data = {
  labels: dates.reverse(),
  datasets: [
    {
      label: 'Enquiries Registered',
      fill: true,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0],
    }
  ]
};
const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
];
const useStyles = (theme) => ({
  large1: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#f0bc11'
  },
  large2: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#4cc42d'
  },
  large3: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#f50c23'
  },
  large4: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.4)',
    backgroundColor: '#0dc9d6'
  },
  root: {
    marginTop: 60,
  },
  Card: {
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.4)',
    borderRadius: 5
  },
});
const today = new Date()
class EnquiryDashboard extends Component {

  constructor() {
    super()
    this.state = {
      opened: '',
      enquiry_id: '',
      close: '',
      total: '',
      todaydata: [],
      lastseven: '',
      entity_name: '',
      person_name: '',
      mobile1: '',
      mobile2: '',
      gstno: '',
      email: '',
      address: '',
      postal_code: '',
      state: 0,
      district: '',
      city: '',
      errors: '',
      customer_type: '',
      grade: 'A',
      deals_with: [],
      openerror: false,
      opendialog: false,
      whatsapp1: false,
      whatsapp2: false,
      chancesofconversion: 50,
      unit: 1,
      products: [],
      product_id: 1,
      product: null,
      lead_source: '',
      beginning_date: today,
      last_date: today,
      follow_date: today,
      remark: '',
      reason: '',
      whatappschecked: false,
      phonechecked: false,
      followchecked: false,
      addedby: [],
      res_customertype: [],
      res_customerdealswith: [],
      res_leadsource: []
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleView = this.handleView.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    axios.post($links.enquirydashboard, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.auth) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          data.datasets[0].data = res.data.graph.reverse()
          this.setState({
            opened: res.data.opened, close: res.data.closed, total: res.data.total, todaydata: res.data.todaydata,
            lastseven: res.data.lastseven
          })
        }
        else {
          alert(res.data.message)
        }
      })
    axios.post($links.enquiry, { cookies: localStorage.getItem('jwt') })
      .then(res => {
        if (res.data.errors === true) {
          alert(res.data.message)
          window.location.replace('/')
        }
        else if (res.data.errors === false) {
          this.setState({ products: res.data.message, res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith, customer_type: res.data.type })
        } else {
          this.setState({ errors: res.data.message })

        }
      })
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleClose() {
    this.setState({ opendialog: false })
  }
  handleCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
    if (e.target.name === 'whatappschecked' && e.target.checked) {
      var win = window.open("https://api.whatsapp.com/send?phone=91" + this.state.mobile1);
      var timer = setInterval(function () {
        win.close()
        clearInterval(timer);
      }, 3000);
    }
    else if (e.target.name === 'phonechecked' && e.target.checked) {
      window.open("tel:" + this.state.mobile1);
    }
  };
  handleView = async (rowData) => {
    await axios.post($links.viewenquiry, { cookies: localStorage.getItem('jwt'), enquiry_id: rowData.enquiry_id })
      .then(res => {

        if (res.data.errors === false) {
          this.setState({
            enquiry_id: rowData.enquiry_id,
            entity_name: res.data.message.entity_name,
            person_name: res.data.message.person_name,
            mobile1: res.data.message.mobile[0],
            mobile2: res.data.message.mobile[1],
            gstno: res.data.message.gstno,
            email: res.data.message.email,
            address: res.data.message.address,
            postal_code: res.data.message.postal_code,
            state: res.data.message.state,
            district: res.data.message.district,
            city: res.data.message.city,
            customer_type: res.data.message.customer_type,
            grade: res.data.message.grade,
            deals_with: res.data.message.deals_with,
            whatsapp1: Boolean(res.data.message.whatsapp[0]),
            whatsapp2: Boolean(res.data.message.whatsapp[1]),
            chancesofconversion: res.data.message.chancesofconversion,
            unit: res.data.message.unit,
            products: res.data.products,
            product_id: res.data.message.product_id,
            lead_source: res.data.message.lead_source,
            beginning_date: res.data.message.beginning_date,
            last_date: res.data.message.last_date,
            follow_date: res.data.message.follow_date,
            remark: res.data.message.remark,
            whatappschecked: res.data.message.checked[0],
            phonechecked: res.data.message.checked[1],
            followchecked: res.data.message.checked[2],
            product: res.data.products[res.data.index],
            addedby: res.data.addedby,
            reason: res.data.message.reason,
            res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith,
            opendialog: true
          })
        }
        else {
          alert(res.data.message)
        }
      })
  }
  onSubmit = async (e) => {
    e.preventDefault()
    const enquiry_details = {
      enquiry_id: this.state.enquiry_id,
      entity_name: this.state.entity_name,
      person_name: this.state.person_name,
      mobile1: this.state.mobile1,
      mobile2: this.state.mobile2,
      gstno: this.state.gstno,
      email: this.state.email,
      address: this.state.address,
      postal_code: this.state.postal_code,
      state: this.state.state,
      district: this.state.district,
      city: this.state.city,
      customer_type: this.state.customer_type,
      grade: this.state.grade,
      deals_with: this.state.deals_with,
      whatsapp1: this.state.whatsapp1,
      whatsapp2: this.state.whatsapp2,
      chancesofconversion: this.state.chancesofconversion,
      unit: this.state.unit,
      product_id: this.state.product_id,
      lead_source: this.state.lead_source,
      beginning_date: this.state.beginning_date,
      last_date: this.state.last_date,
      follow_date: this.state.follow_date,
      remark: this.state.remark,
      reason: this.state.reason,
      checked: [this.state.whatappschecked,
      this.state.phonechecked,
      this.state.followchecked],
      cookies: localStorage.getItem('jwt')
    }
    await axios.post($links.updateenquiry, enquiry_details)
      .then(res => {
        if (res.data.errors === false) {
          alert(res.data.message)
          window.location.reload()
        } else {
          this.setState({ errors: res.data.message, open: true })
        }
      })
  }
  render() {
    const nowrap = { whiteSpace: 'nowrap' }
    const { classes } = this.props
    var { todaydata = [] } = this.state
    var { products = [] } = this.state
    var { res_customertype = [] } = this.state
    var { res_customerdealswith = [] } = this.state
    var { res_leadsource = [] } = this.state
    const addedby = this.state.addedby
    return (
      <div className={classes.root}>
        <Dialog open={this.state.opendialog} onClose={this.handleClose} fullWidth maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogContent style={{ maxHeight: 900 }}>
            <Snackbar open={this.state.openerror} autoHideDuration={4000} onClose={(e, reason) => {
              if (reason === 'clickaway') {
                return
              }
              this.setState({ openerror: false })
            }}>
              <Alert onClose={(e, reason) => {
                if (reason === 'clickaway') {
                  return
                }
                this.setState({ openerror: false })
              }} severity="error">
                {this.state.errors}
              </Alert>
            </Snackbar>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="outline-flexible"
                  label="Entity Name"
                  placeholder="Enter Entity Name"
                  variant="outlined"
                  fullWidth
                  name="entity_name"
                  value={this.state.entity_name}
                  autoFocus
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Person Name"
                  placeholder="Enter Name"
                  variant="outlined"
                  fullWidth
                  name="person_name"
                  value={this.state.person_name}
                  onChange={this.onChange}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Mobile Number 1"
                  placeholder="Mobile Number 1"
                  fullWidth
                  name="mobile1"
                  value={this.state.mobile1}
                  onChange={this.onChange}
                  id="outlined-start-adornment"
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start" >
                        <Tooltip title="Set this as Whatsapp Number">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="whatsapp1"
                                color="primary"
                                checked={this.state.whatsapp1}
                                onChange={this.handleCheckbox}
                              />
                            }
                          />
                        </Tooltip>
                      </InputAdornment>,
                    type: "Number"
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Mobile Number 2"
                  placeholder="(Optional)"
                  fullWidth
                  name="mobile2"
                  value={this.state.mobile2}
                  onChange={this.onChange}
                  id="outlined-start-adornment1"
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start" >
                        <Tooltip title="Set this as Whatsapp Number">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="whatsapp2"
                                color="primary"
                                checked={this.state.whatsapp2}
                                onChange={this.handleCheckbox}
                              />
                            }
                          />
                        </Tooltip>
                      </InputAdornment>,
                    type: "Number"
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="GSTN"
                  placeholder="GST Number"
                  variant="outlined"
                  fullWidth
                  name="gstno"
                  value={this.state.gstno}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  placeholder="Enter Address"
                  variant="outlined"
                  fullWidth
                  name="address"
                  value={this.state.address}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Postal Code"
                  placeholder="6 digit number"
                  variant="outlined"
                  fullWidth
                  name="postal_code"
                  value={this.state.postal_code}
                  onChange={this.onChange}
                  inputProps={{ maxLength: 6 }}
                  onInput={(e) => {
                    if (e.target.value) {
                      if (e.target.value.length === 6) {
                        const postal = {
                          postal_code: e.target.value
                        }
                        axios.post($links.postal_code, postal)
                          .then(res => {

                            if (res.data.errors) {
                              this.setState({ errors: res.data.message, open: true })
                            }
                            else {
                              this.setState({ state: res.data.state, district: res.data.district, city: res.data.city })
                            }
                          })
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.state}
                    onChange={this.onChange}
                    label="State"
                    name="state"
                  >
                    <option value="0" disabled>SELECT STATE</option>
                    <option value="ANDHRA PRADESH">Andhra Pradesh</option>
                    <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                    <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                    <option value="ASSAM">Assam</option>
                    <option value="BIHAR">Bihar</option>
                    <option value="CHANDIGARH">Chandigarh</option>
                    <option value="CHATTISGARH">Chhattisgarh</option>
                    <option value="DADRA & NAGAR HAVELI">Dadra and Nagar Haveli</option>
                    <option value="DAMAN AND DIU">Daman and Diu</option>
                    <option value="DELHI">Delhi</option>
                    <option value="LAKSHADWEEP">Lakshadweep</option>
                    <option value="PUDUCHERRY">Puducherry</option>
                    <option value="GOA">Goa</option>
                    <option value="GUJARAT">Gujarat</option>
                    <option value="HARYANA">Haryana</option>
                    <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                    <option value="JAMMU AND KASHMIR">Jammu and Kashmir</option>
                    <option value="JHARKHAND">Jharkhand</option>
                    <option value="KARNATAKA">Karnataka</option>
                    <option value="KERALA">Kerala</option>
                    <option value="MADHYA PRADESH">Madhya Pradesh</option>
                    <option value="MAHARASHTRA">Maharashtra</option>
                    <option value="MANIPUR">Manipur</option>
                    <option value="MEGHALAYA">Meghalaya</option>
                    <option value="MIZORAM">Mizoram</option>
                    <option value="NAGALAND">Nagaland</option>
                    <option value="ODISHA">Odisha</option>
                    <option value="PUNJAB">Punjab</option>
                    <option value="RAJASTHAN">Rajasthan</option>
                    <option value="SIKKIM">Sikkim</option>
                    <option value="TAMIL NADU">Tamil Nadu</option>
                    <option value="TELANGANA">Telangana</option>
                    <option value="TRIPURA">Tripura</option>
                    <option value="UTTAR PRADESH">Uttar Pradesh</option>
                    <option value="UTTARAKHAND">Uttarakhand</option>
                    <option value="WEST BENGAL">West Bengal</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="City"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={this.state.city}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="District"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={this.state.district}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Customer Type</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.customer_type}
                    onChange={this.onChange}
                    label="Select Custom"
                    name="customer_type"
                  >
                    <option disabled>Select Customer Type</option>
                    {res_customertype.map(res_customertype => (
                      <option key={res_customertype} value={res_customertype}>{res_customertype}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Select Grade</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.grade}
                    onChange={this.onChange}
                    label="Select Custo"
                    name="grade"
                  >
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Units"
                  placeholder=""
                  variant="outlined"
                  fullWidth
                  name="unit"
                  InputProps={{
                    type: "Number"
                  }}
                  value={this.state.unit}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="combo-box-demo"
                  options={products}
                  value={this.state.product}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      this.setState({ product_id: newValue.product_id });
                    }
                  }}
                  getOptionLabel={(option) => "Product Name: " + option.name_of_good + " | Model: " + option.model}
                  name="name"
                  renderInput={(params) => <TextField {...params} label="Search Product" variant="outlined" fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={res_customerdealswith}
                  fullWidth
                  value={this.state.deals_with}
                  getOptionLabel={(option) => option}
                  onChange={(e, newValue) => {
                    this.setState({ deals_with: newValue })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Customer Deals With" fullWidth placeholder="Select Dealer deals with" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">Lead Source</InputLabel>
                  <Select
                    fullWidth
                    native
                    value={this.state.lead_source}
                    onChange={this.onChange}
                    label="Lead Source"
                    name="lead_source"
                  >
                    <option disabled>Select Lead Source</option>
                    {res_leadsource.map(res_leadsource => (
                      <option key={res_leadsource} value={res_leadsource}>{res_leadsource}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  <KeyboardDatePicker
                    fullWidth
                    format="dd/MM/yyyy"
                    margin="dense"
                    id="date-picker-inline1"
                    name="beginning_date"
                    label="Beginning"
                    value={this.state.beginning_date}
                    onChange={(date) => {
                      this.setState({ beginning_date: date });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        format="dd/MM/yyyy"
                        margin="dense"
                        id="date-picker-inline3"
                        name="last_date"
                        label="Last Contact"
                        value={this.state.last_date}
                        onChange={(date) => {
                          this.setState({ last_date: date });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        format="dd/MM/yyyy"
                        margin="dense"
                        id="date-picker-inline2"
                        name="follow_date"
                        label="Follow Up"
                        value={this.state.follow_date}
                        onChange={(date) => {
                          this.setState({ follow_date: date });
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.whatappschecked}
                            onChange={this.handleCheckbox}
                            name="whatappschecked"
                            color="primary"
                          />
                        }
                        label="WhatsApp Send"
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.phonechecked}
                            onChange={this.handleCheckbox}
                            name="phonechecked"
                            color="primary"
                          />
                        }
                        label="Phone Call Done"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.followchecked}
                            onChange={this.handleCheckbox}
                            name="followchecked"
                            color="primary"
                          />
                        }
                        label="Follow Up Done"
                      />
                    </Grid>

                  </Grid></Grid>
              </MuiPickersUtilsProvider>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Remarks On Enquiry"
                  multiline
                  rowsMax={5}
                  placeholder="Remarks"
                  variant="outlined"
                  fullWidth
                  name="remark"
                  value={this.state.remark}
                  onChange={this.onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography id="discrete-slider" align="left" gutterBottom><b>Chances Of Conversion :</b></Typography>
                <Slider
                  value={this.state.chancesofconversion}
                  onChange={(e, newValue) => {
                    this.setState({ chancesofconversion: newValue })
                  }}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  min={0}
                  max={100}
                  marks={marks}
                />
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              {this.state.followchecked ?
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Reason for Follow Up Done"
                    multiline
                    rows={4}
                    placeholder="Reason"
                    variant="outlined"
                    fullWidth
                    name="reason"
                    value={this.state.reason}
                    onChange={this.onChange}
                  />
                </Grid> : ''}
              <Grid item xs={12} sm={5} style={{ marginTop: 30 }}>
                <Typography><b>Added/Updated By:</b> {addedby[0] + ', ' + addedby[1]}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSubmit} color="primary">Update enquiry</Button>
            <Button onClick={this.handleClose} color="primary"> Close</Button>
          </DialogActions>
        </Dialog>
        <Container>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={4}>
              <Card className={classes.Card}>
                <CardContent>
                  <Grid container >
                    <Grid item xs={9} sm={9}>
                      <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL ENQUIRIES OPENED</b></Typography>
                      <Typography align="left" variant="h6">{this.state.opened}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Avatar className={classes.large1}><PeopleAlt fontSize="large" /></Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.Card}>
                <CardContent>
                  <Grid container >
                    <Grid item xs={9} sm={9}>
                      <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL ENQUIRIES CLOSED</b></Typography>
                      <Typography align="left" variant="h6">{this.state.close}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Avatar className={classes.large2}></Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.Card}>
                <CardContent>
                  <Grid container >
                    <Grid item xs={9} sm={9}>
                      <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL ENQUIRIES</b></Typography>
                      <Typography align="left" variant="h6">{this.state.total}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Avatar className={classes.large3}><CameraAlt fontSize="large" /></Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <MaterialTable style={{ borderRadius: 15, borderBottom: '3px solid red', borderTop: '3px solid red', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.4)' }}
                title="Today's Enquiries"
                columns={[
                  { title: 'ID', field: 'enquiry_id', cellStyle: { width: '1%' } },
                  { title: 'Name', field: 'entity_name', headerStyle: nowrap, cellStyle: nowrap },
                  { title: 'District', field: 'district', cellStyle: nowrap, headerStyle: nowrap },
                  { title: 'Created', field: 'create_at', headerStyle: nowrap, cellStyle: nowrap, hidden: true },
                  { title: 'Mobile Number', field: 'mobile1', headerStyle: nowrap },
                  { title: 'Status', field: 'status', lookup: { 0: "Opened", 1: "Closed" } },
                ]}
                data={todaydata.map(todaydata => (
                  {
                    enquiry_id: todaydata.enquiry_id, entity_name: [todaydata.entity_name, todaydata.person_name].filter(Boolean).join(", "), district: todaydata.district,
                    status: todaydata.status, create_at: todaydata.create_at, mobile1: todaydata.mobile[1] ? todaydata.mobile[0] + ' ' + todaydata.mobile[1] : todaydata.mobile[0],
                  }))}
                actions={[
                  {
                    icon: 'visibility',
                    tooltip: 'View Enquiry',
                    onClick: (event, rowData) => {
                      this.handleView(rowData)
                    }
                  },
                ]}
                options={{
                  pageSize: 10,
                  padding: 'dense',
                  actionsColumnIndex: -1,
                  sorting: false,
                  draggable: false,
                  rowStyle: rowData => {

                    if (rowData.enquiry_id === this.state.enquiry_id) {
                      return { backgroundColor: '#ebebeb' };
                    } else if (rowData.status === 0) {
                      return { backgroundColor: '#ebe65e' };
                    } else if (rowData.status === 1) {
                      return { backgroundColor: '#91e874' };
                    }
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.Card}>
                <CardContent>
                  <Typography variant="h4">ENQUIRIES IN LAST 7 DAYS</Typography><br />
                  <Line ref="chart" data={data} options={{
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true
                        }
                      }]
                    }
                  }} />
                  <br />
                </CardContent>
              </Card>
              <br />
              <Card className={classes.Card}>
                <CardContent>
                  <Grid container >
                    <Grid item xs={9} sm={10}>
                      <Typography align="left" variant='subtitle1' color="textSecondary" ><b>TOTAL ENQUIRIES IN LAST 7 DAYS</b></Typography>
                      <Typography align="left" variant="h6">{this.state.lastseven}</Typography>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <Avatar className={classes.large4}><LocalShipping fontSize="large" /></Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <br />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button size="large" variant="outlined" color="primary" onClick={() => this.props.history.push('/enquiry/allenquires')}>All Enquiries</Button>
            </Grid>
          </Grid>
          <br />
        </Container>
      </div >
    );
  }
}
export default withStyles(useStyles)(EnquiryDashboard)
