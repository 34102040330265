import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button, Container, Grid, Paper, TextField, Typography, Select, FormControl, InputLabel, Snackbar, InputAdornment,
    Checkbox, Tooltip, FormControlLabel
} from '@material-ui/core'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import $links from './variable'
import { Autocomplete } from '@material-ui/lab';
const useStyles = (theme) => ({

    dates: {
        margin: theme.spacing(2, 1, 3),

    },
    addorder: {
        position: 'absolute',
        width: 150
    },
    adduser: {
        margin: theme.spacing(0, 20, 0),
        width: 150
    }
});

class EditCustomer extends Component {

    constructor() {
        super()
        this.state = {
            customer_id: localStorage.getItem('transid'),
            entity_name: '',
            person_name: '',
            mobile1: '',
            mobile2: '',
            gstno: '',
            email: '',
            address: '',
            postal_code: '',
            state: 0,
            district: '',
            city: '',
            errors: '',
            balance: '',
            open: false,
            type: '',
            grade: 'A',
            deals_with: [],
            whatsapp: [],
            whatsapp1: false,
            whatsapp2: false,
            disabled: false,
            res_customertype: [],
            res_customerdealswith: [],
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
        const transactioncustomer = {
            customer_id: localStorage.getItem('transid'),
            cookies: localStorage.getItem('jwt')
        }
        axios.post($links.selectoptions, { cookies: localStorage.getItem('jwt') })
            .then(res => {
                if (res.data.errors === true) {
                    this.setState({ errors: res.data.message, open: true })
                } else {
                    this.setState({
                        res_customertype: res.data.customertype, res_customerdealswith: res.data.customerdealswith
                    })
                }
            })
        axios.post($links.findcustomer, transactioncustomer)

            .then(res => {
                if (res.data.auth) {
                    alert(res.data.message)
                    window.location.replace('/')
                }
                else if (res.data.errors === false) {
                    this.setState({
                        entity_name: res.data.message.entity_name,
                        person_name: res.data.message.person_name,
                        mobile1: res.data.message.mobile1,
                        mobile2: res.data.message.mobile2,
                        gstno: res.data.message.gstno,
                        email: res.data.message.email,
                        address: res.data.message.address,
                        postal_code: res.data.message.postal_code,
                        state: res.data.message.state,
                        district: res.data.message.district,
                        balance: res.data.message.balance,
                        city: res.data.message.city,
                        whatsapp1: res.data.whatsapp1,
                        whatsapp2: res.data.whatsapp2,
                        deals_with: res.data.message.deals_with,
                        type: res.data.message.type,
                        grade: res.data.message.grade,
                    })
                    if (res.data.message.type === '') {
                        this.setState({ type: "Dealer" })
                    } if (res.data.message.type === '') {
                        this.setState({ grade: "A" })
                    }
                }
                else {
                    alert(res.data.message)
                }
            })
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleClose(e, reason) {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ open: false })

    }
    handleCheckbox(e) {
        this.setState({ [e.target.name]: e.target.checked });
    };
    onSubmit = async (e) => {
        e.preventDefault()
        const customer = {
            customer_id: this.state.customer_id,
            entity_name: this.state.entity_name,
            person_name: this.state.person_name,
            mobile1: this.state.mobile1,
            mobile2: this.state.mobile2,
            gstno: this.state.gstno,
            email: this.state.email,
            address: this.state.address,
            postal_code: this.state.postal_code,
            state: this.state.state,
            district: this.state.district,
            city: this.state.city,
            balance: this.state.balance,
            whatsapp1: this.state.whatsapp1,
            whatsapp2: this.state.whatsapp2,
            deals_with: this.state.deals_with,
            type: this.state.type,
            grade: this.state.grade,
            cookies: localStorage.getItem('jwt')
        }
        await axios.post($links.EditCustomer, customer)
            .then(res => {
                if (res.data.errors === false) {
                    alert(res.data.message)
                    this.props.history.push('/customer')
                } else {
                    this.setState({ errors: res.data.message, open: true })
                }
            })

    }
    render() {
        const { res_customertype = [] } = this.state
        const { res_customerdealswith = [] } = this.state
        const { classes } = this.props
        return (
            <div>
                <Paper>
                    <Container style={{ marginTop: 50 }}>
                        <Typography variant="h4" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Edit Customer</Typography>
                        <Typography variant="body1" align="left" style={{ paddingTop: 10, paddingBottom: 10 }}>Enter Details</Typography>
                        <Snackbar open={this.state.open} autoHideDuration={4000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity="error">
                                {this.state.errors}
                            </Alert>
                        </Snackbar>
                        <form onSubmit={this.onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        id="outline-flexible"
                                        label="Entity Name"
                                        placeholder="Enter Name"
                                        variant="outlined"
                                        fullWidth
                                        name="entity_name"
                                        value={this.state.entity_name}
                                        autoFocus
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Person Name"
                                        placeholder="Enter Name"
                                        variant="outlined"
                                        fullWidth
                                        name="person_name"
                                        value={this.state.person_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Mobile Number 1"
                                        placeholder="Mobile Number 1"
                                        fullWidth
                                        name="mobile1"
                                        value={this.state.mobile1}
                                        onChange={this.onChange}
                                        id="outlined-start-adornment"
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" >
                                                    <Tooltip title="Set this as Whatsapp Number">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="whatsapp1"
                                                                    color="primary"
                                                                    checked={this.state.whatsapp1}
                                                                    onChange={this.handleCheckbox}
                                                                />
                                                            }
                                                        />
                                                    </Tooltip>
                                                </InputAdornment>,
                                            type: "Number"
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Mobile Number 2"
                                        placeholder="(Optional)"
                                        fullWidth
                                        name="mobile2"
                                        value={this.state.mobile2}
                                        onChange={this.onChange}
                                        id="outlined-start-adornment1"
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" >
                                                    <Tooltip title="Set this as Whatsapp Number">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="whatsapp2"
                                                                    color="primary"
                                                                    checked={this.state.whatsapp2}
                                                                    onChange={this.handleCheckbox}
                                                                />
                                                            }
                                                        />
                                                    </Tooltip>
                                                </InputAdornment>,
                                            type: "Number"
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="GSTN"
                                        placeholder="GST Number"
                                        variant="outlined"
                                        fullWidth
                                        name="gstno"
                                        value={this.state.gstno}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Email Address"
                                        variant="outlined"
                                        fullWidth
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Address"
                                        placeholder="Enter Address"
                                        variant="outlined"
                                        fullWidth
                                        name="address"
                                        value={this.state.address}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Postal Code"
                                        placeholder="6 digit number"
                                        variant="outlined"
                                        fullWidth
                                        name="postal_code"
                                        value={this.state.postal_code}
                                        onChange={this.onChange}
                                        inputProps={{ maxLength: 6 }}
                                        onInput={(e) => {

                                            if (e.target.value.length === 6) {
                                                const postal = {
                                                    postal_code: e.target.value
                                                }
                                                axios.post($links.postal_code, postal)
                                                    .then(res => {

                                                        if (res.data.errors) {
                                                            this.setState({ errors: res.data.message, open: true })
                                                        }
                                                        else {
                                                            this.setState({ state: res.data.state, district: res.data.district, city: res.data.city })
                                                        }
                                                    })
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={this.state.state}
                                            onChange={this.onChange}
                                            label="State"
                                            name="state"
                                        >
                                            <option value="0" disabled>SELECT STATE</option>
                                            <option value="ANDHRA PRADESH">Andhra Pradesh</option>
                                            <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                                            <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                                            <option value="ASSAM">Assam</option>
                                            <option value="BIHAR">Bihar</option>
                                            <option value="CHANDIGARH">Chandigarh</option>
                                            <option value="CHATTISGARH">Chhattisgarh</option>
                                            <option value="DADRA & NAGAR HAVELI">Dadra and Nagar Haveli</option>
                                            <option value="DAMAN AND DIU">Daman and Diu</option>
                                            <option value="DELHI">Delhi</option>
                                            <option value="LAKSHADWEEP">Lakshadweep</option>
                                            <option value="PUDUCHERRY">Puducherry</option>
                                            <option value="GOA">Goa</option>
                                            <option value="GUJARAT">Gujarat</option>
                                            <option value="HARYANA">Haryana</option>
                                            <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                                            <option value="JAMMU AND KASHMIR">Jammu and Kashmir</option>
                                            <option value="JHARKHAND">Jharkhand</option>
                                            <option value="KARNATAKA">Karnataka</option>
                                            <option value="KERALA">Kerala</option>
                                            <option value="MADHYA PRADESH">Madhya Pradesh</option>
                                            <option value="MAHARASHTRA">Maharashtra</option>
                                            <option value="MANIPUR">Manipur</option>
                                            <option value="MEGHALAYA">Meghalaya</option>
                                            <option value="MIZORAM">Mizoram</option>
                                            <option value="NAGALAND">Nagaland</option>
                                            <option value="ODISHA">Odisha</option>
                                            <option value="PUNJAB">Punjab</option>
                                            <option value="RAJASTHAN">Rajasthan</option>
                                            <option value="SIKKIM">Sikkim</option>
                                            <option value="TAMIL NADU">Tamil Nadu</option>
                                            <option value="TELANGANA">Telangana</option>
                                            <option value="TRIPURA">Tripura</option>
                                            <option value="UTTAR PRADESH">Uttar Pradesh</option>
                                            <option value="UTTARAKHAND">Uttarakhand</option>
                                            <option value="WEST BENGAL">West Bengal</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="District"
                                        placeholder=""
                                        variant="outlined"
                                        fullWidth
                                        name="district"
                                        value={this.state.district}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="City"
                                        placeholder=""
                                        variant="outlined"
                                        fullWidth
                                        name="city"
                                        value={this.state.city}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Customer Type</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={this.state.type}
                                            onChange={this.onChange}
                                            label="Select Custom"
                                            name="type"
                                        >
                                            <option disabled>Select Customer Type</option>
                                            {res_customertype.map(res_customertype => (
                                                <option key={res_customertype} value={res_customertype}>{res_customertype}</option>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Select Grade</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={this.state.grade}
                                            onChange={this.onChange}
                                            label="Select Custo"
                                            name="grade"
                                        >
                                            <option value='A'>A</option>
                                            <option value='B'>B</option>
                                            <option value='C'>C</option>
                                            <option value='D'>D</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        multiple
                                        id="multiple-limit-tags"
                                        options={res_customerdealswith}
                                        fullWidth
                                        value={this.state.deals_with}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, newValue) => {
                                            this.setState({ deals_with: newValue })
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Customer Deals With" fullWidth placeholder="Select Dealer deals with" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        label="Balance"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        name="balance"
                                        value={this.state.balance}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                            <Button variant="contained" type="submit" color="primary" style={{ margin: 20 }}>Edit Customer</Button>
                            <Button variant="contained" onClick={async () => {
                                await axios.post($links.deletecustomer, { customer_id: this.state.customer_id, cookies: localStorage.getItem('jwt') })
                                    .then(res => {
                                        if (res.data.errors === false) {
                                            alert(res.data.message)
                                            this.props.history.push('/customer')
                                        } else {
                                            alert(res.data.message)
                                        }
                                    })
                            }} color="secondary" style={{ margin: 20 }}>Delete Customer</Button>
                        </form>
                    </Container>

                </Paper>

            </div>

        );
    }
}

export default withStyles(useStyles)(EditCustomer)